import React, { Component } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { cmsGetStatePromise, cmsGetCountriesPromise } from './CallMSAPI';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class CountryIdAsyncSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: null,
            state: null,
            loading: false,
            handleChangeCount : 0
        };

        this.loadOptions = this.loadOptions.bind(this);
        this.loadStateOptions = this.loadStateOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadCountryValue = this.loadCountryValue.bind(this);
    }

    componentDidMount() {
        this.loadValue();
    }

    loadValue() {
        var self = this;

        if (!this.props.stateValue && this.props.countryValue != this.state.country && this.state.loading == false) {
            self.loadCountryValue(this.props.countryValue);
            return;
        }

        if (Number.isInteger(this.props.stateValue) && this.props.stateValue != this.state.stateid && this.state.loading == false) {

            this.setState({ loading: true }, function () {
                // Load state value and country value...
                cmsGetStatePromise(this.props.account.Id, { stateId: this.props.stateValue })
                    .then(function (data) {
                        if (data && data.data && data.data.Results) {
                            var state = data.data.Results[0];
                            self.setState({ stateid: state, loading: false }, function () {
                                // Ensure default gets propagated up as well
                                self.handleChange(self.props.stateName, state);
                                if ((this.state.country && state.CountryId != this.state.country.Id)
                                    || !this.state.country) {
                                    self.loadCountryValue(state.CountryId)
                                }
                            });
                        } else {
                            toast.error("Could not retrieve requested state value");
                        }
                    })
            })

        } else {
            self.setState({
                state: this.props.stateValue
            }, function () {
                    if (this.state.country && this.state.country.Id && this.props.stateValue.CountryId != this.state.country.Id) {
                        self.loadCountryValue(this.props.stateValue.CountryId);
                    }
            });
        }
    }

    loadCountryValue(countryValue) {
        var self = this;
        if (countryValue && Number.isInteger(countryValue) && this.props.countryValue != this.state.country && this.state.loading == false) {
            this.setState({ loading: true }, function () {
                cmsGetCountriesPromise(self.props.account.Id, { 'countryId': countryValue })
                    .then(function (data) {
                        if (data && data.data && data.data.Results) {
                            // Ensure default gets propagated up as well
                            self.setState({ country: data.data.Results[0], loading: false }, function () {
                                self.handleChange(self.props.countryName, data.data.Results[0]);
                            });
                        }
                    });
            })
        } else {
            self.setState({
                country: this.props.defaultOptions && !countryValue
                    ? this.props.defaultOptions[0]
                    : countryValue
            })
        }
    }

    async loadOptions(input, loadOpts, { page }) {
        var opts = {
            CurrentPage: page
        };
        if (input !== '') {
            opts['searchText'] = input;
        }
        var response = await cmsGetCountriesPromise(this.props.account.Id, opts);
        var data = response.data;

        return {
            options: data.Results,
            hasMore: data.PageCount > data.CurrentPage,
            additional: {
                page: page + 1
            }
        };
    }

    async loadStateOptions(input, loadOpts, { page }) {
        var opts = {
            CurrentPage: page
        };
        if (input !== '') {
            opts['searchText'] = input;
        }
        opts['countryId'] = this.state.country.Id;
        var response = await cmsGetStatePromise(this.props.account.Id, opts);
        var data = response.data;

        return {
            options: data.Results,
            hasMore: data.PageCount > data.CurrentPage,
            additional: {
                page: page + 1
            }
        };
    }

    handleChange(name, opt) {
        // Let upstream/formik know about the change
        console.log("Calling upstream for " + name + " change in CountryIdAsyncSelect");
        this.props.onChange(name, opt.Id);

        this.setState(prevState => ({ handleChangeCount: prevState.handleChangeCount + 1 }));

        if (name === this.props.countryName) {
            this.setState(prevState => ({
                country: opt
            }));
            /* When changing between countries that both have states, it is necessary to reset
             * the state value as below, or an invalid state choice from the previous country
             * value still shows as being selected in the state dropdown */
            if (this.state.state && this.state.state.CountryId != opt.Id) {
                this.setState(prevState => ({
                    state: null
                }));
            }
            if (!opt.HasStates) {
                this.props.onChange('StateId', null);
            }
        } else {
            this.setState({ state: opt });
        }
    }

    render() {
        var self = this;

        var country = (
            <AsyncPaginate
                cacheOptions
                getOptionLabel={(option) => option.Name}
                getOptionValue={(option) => option.Id}
                loadOptions={self.loadOptions}
                className="select-dropdown"
                defaultOptions={self.props.defaultOptions}
                isDisabled={this.props.disabled}

                name={this.props.countryName}
                value={this.state.country}
                onChange={(opt) => this.handleChange(this.props.countryName, opt)}

                additional={{
                    page: 1,
                }}
            />
        );

        var states = null;
        if (this.state.country
            && this.state.country.HasStates
            && this.props.stateName
        ) {
            states = (
                <>
                    <label className="state-label">State / Province *</label>
                    <AsyncPaginate
                        key={'state-select-' + this.state.handleChangeCount}
                        cacheOptions
                        getOptionLabel={(option) => option.Name}
                        getOptionValue={(option) => option.Id}
                        loadOptions={self.loadStateOptions}
                        className="select-dropdown"
                        defaultOptions
                        isDisabled={this.props.disabled}

                        name={this.props.stateName}
                        value={this.state.state}
                        onChange={(opt) => this.handleChange(this.props.stateName, opt)}

                        additional={{
                            page: 1,
                        }}
                    />
                </>
            );
        }

        return (<>{country}{states}</>);
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = () => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CountryIdAsyncSelect);

//Not used?
export const getCountryId = (IdVal, defaultVal) => {
    if (Number.isInteger(IdVal)) {
        return IdVal;
    } else if (typeof IdVal === 'object' && IdVal !== null && IdVal.hasOwnProperty('Id')) {
        return IdVal.Id;
    } else {
        return defaultVal;
    }
}