var queryString = require('query-string');

export function openPopupWindow(urlNavigate, title) {
    let popUpWidth = 483;
    let popUpHeight = 675;

    /**
    * adding winLeft and winTop to account for dual monitor
    * using screenLeft and screenTop for IE8 and earlier
    */
    var winLeft = window.screenLeft ? window.screenLeft : window.screenX;
    var winTop = window.screenTop ? window.screenTop : window.screenY;
    /**
    * window.innerWidth displays browser window's height and width excluding toolbars
    * using document.documentElement.clientWidth for IE8 and earlier
    */
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var left = ((width / 2) - (popUpWidth / 2)) + winLeft;
    var top = ((height / 2) - (popUpHeight / 2)) + winTop;

    var popupWindow = window.open(urlNavigate, title, 'width=' + popUpWidth + ', height=' + popUpHeight + ', top=' + top + ', left=' + left);
    console.log(popupWindow);

    if (popupWindow && popupWindow.focus) {
        popupWindow.focus();
    }

    return popupWindow;
}

export function getExternalURI(loc, serviceCode, syncSettings) {
    var redirectUri = loc + '/pbxDummy.html';
    var redirectUriComp = encodeURIComponent(redirectUri);
    var url = '';
    var targetField = '';

    if (serviceCode.startsWith("8X8")) {
        var ssoUriVal = syncSettings.find(x => x.Key === 'SSOURI');
        url = ssoUriVal.Value + '?redirectUrl=' + redirectUriComp;
        targetField = 'code';
    } else if (serviceCode.startsWith('KAZOO')) {
        var apiUriVal = syncSettings.find(x => x.Key === 'APIURI');
        var deviceTypeVal = syncSettings.find(x => x.Key === 'DeviceType');
        var deviceNamePrefixVal = syncSettings.find(x => x.Key === 'DeviceNamePrefix'); 
        var includeUnassignedDevicesVal = syncSettings.find(x => x.Key === 'IncludeUnassignedDevices');

        var apiUriPart = (apiUriVal ? '&api_uri=' + apiUriVal.Value : '');
        var deviceTypePart = (deviceTypeVal ? '&devicetype=' + deviceTypeVal.Value : '');
        var deviceNamePrefixiPart = (deviceNamePrefixVal ? '&devicenameprefix=' + deviceNamePrefixVal.Value : '');
        var includeUnassignedDevicesPart = (includeUnassignedDevicesVal ? '&includeunassigneddevices=' + includeUnassignedDevicesVal.Value : '');

        var accServiceNameStr = '';
        var accServiceName = syncSettings.find(x => x.Key === 'AccountServiceName');
        if (accServiceName) {
            accServiceNameStr = accServiceName.Value;
        }

        url = '/pbxKazoo.html'
            + '?redirect_uri=' + redirectUriComp
            + '&name=' + accServiceNameStr
            + apiUriPart
            + deviceTypePart
            + deviceNamePrefixiPart
            + includeUnassignedDevicesPart;
        targetField = 'code';
    } else if (serviceCode.startsWith('RC')) {

        var signInURI = syncSettings.find(x => x.Key === 'SignInURI');
        var clientId = syncSettings.find(x => x.Key === 'ClientId');

        var signInURIPart = (signInURI ? signInURI.Value : '');
        var clientIdPart = (clientId ? clientId.Value : '');

        redirectUri = loc + '/authDummy.html';
        redirectUriComp = encodeURIComponent(redirectUri);
        
        if (signInURIPart) {
            if (signInURIPart.includes('<REDIRECTURI>') && redirectUriComp) {
                signInURIPart = signInURIPart.replace('<REDIRECTURI>', redirectUriComp);
            }
            if (signInURIPart.includes('<CLIENTID>') && clientIdPart) {
                signInURIPart = signInURIPart.replace('<CLIENTID>', clientIdPart);
            }
        }
        url = signInURIPart;
        targetField = 'code';
    } else {
        if (syncSettings) {
            var signinUri = syncSettings.find(x => x.Key === 'SignInURI');
            var signinClient = syncSettings.find(x => x.Key === 'SignInClientId');
            if (signinUri && signinClient) {
                url = signinUri.Value
                    + '?client_id=' + signinClient.Value
                    + '&response_type=code'
                    + '&redirect_uri=' + redirectUriComp;
                targetField = 'code';
            }
        } else {
            throw new Error("Unknown sync service code: " + serviceCode);
        }
    }

    return {
        url: url,
        targetField: targetField,
        redirectUriComp: redirectUriComp,
        redirectUri: redirectUri
    }
}

function changeLocationUrl(window, url) {
    return new Promise((resolve, reject) => {
        let startTime = Date.now();
        let currentLocation = "";
        try {
            currentLocation = window.location.href;
        }
        catch {
            console.warn("Popup window location cannot be used with an external site");
            reject();
            return;
        }

        window.location.href = url;

        let pageLoadedInterval = setInterval(() => {
            try {
                if (window.location.href !== currentLocation) {
                    clearInterval(pageLoadedInterval);
                    resolve();
                }
            }
            catch {
                clearInterval(pageLoadedInterval);
                resolve();
            }

            let takenSeconds = (Date.now() - startTime) / 1000;
            let awaitingForUrlTakesTooLong = takenSeconds > 60;
            if (awaitingForUrlTakesTooLong) {
                clearInterval(pageLoadedInterval);
                reject();
            }

        }, 5)
    });
}

export function getExternalSyncToken(serviceCode, popup, syncSettings) {
    return new Promise(function (resolve, reject) {
        if (!serviceCode) {
            throw new Error("No sync service code given");
        }

        let numberRequired = 1;
        let uri = getExternalURI(window.location.origin, serviceCode, syncSettings);
        let redirectUri = uri.redirectUri;
        let url = uri.url;
        let targetField = uri.targetField;

        if (!url || url.length === 0) {
            // Shortcut if it's a sync that doesn't need a popup/code
            resolve([]);
            return;
        }

        let popupWindow = popup;
        let externalPopup = false;
        if (!popupWindow) {
            popupWindow = openPopupWindow(url, "Portal Auth");
        } else {
            externalPopup = true;
            changeLocationUrl(popupWindow, url)
                .catch(() => {
                    console.warn("Unable to open url=" + url);
                    reject("Unable to open auth window.");
                })
                .then(() => {
                    let output = [];
                    let pos = 0;
                    let pollTimer = window.setInterval(function () {
                        if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                            window.clearInterval(pollTimer);
                            reject("Auth window closed.");
    
                        } else {
                            try {
                                let loc = popupWindow.location;
                                if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                                    let temp = queryString.parse(loc.search);
                                    temp['request_url'] = url;
                                    temp['redirect_uri'] = redirectUri;
    
                                    if (!temp[targetField]
                                        || (pos > 0 && numberRequired > 1
                                            && temp[targetField] && (temp[targetField] === output[pos - 1].code)
                                        )
                                    ) {
                                        // console.log("Still on previous code value")
                                        return;
                                    } else {
                                        output.push(temp);
                                        pos++;
                                    }
    
                                    window.clearInterval(pollTimer);
                                    if (!externalPopup) {
                                        console.log("closing non-external popup window");
                                        popupWindow.close();
                                    }
                                    console.log("Final ext sync output");
                                    console.log(output);
                                    resolve(output);
                                } else {
                                    // console.log("Not found yet... " + loc);
                                }
                            } catch (e) {
                                console.log("Not external root: " + e.message);
                                // whilst not our origin it'll error, just ignore until it's back to our origin
                            }
                        }
                    }, 100);
                });
        }
    })
}

export function getExternalSyncFormattedStartData(serviceCode, code, syncSettings) {
    if (serviceCode.startsWith("8X8")) {
        return {
            "8x8AuthCode": {
                Type: "AuthorizationCode",
                Value: code
            }
        };
    } else if (serviceCode.startsWith('KAZOO')) {
        return {
            "Kazoo": {
                Type: "AuthorizationCode",
                Value: code
            }
        };
    } else if (serviceCode.startsWith('RC')) {
        return {
            "RingCentralAuthCode": {
                RedirectUri: window.location.origin + '/authDummy.html',
                Type: "AuthorizationCode",
                Value: code
            }
        };
    }else {
        if (syncSettings && code) {
            return {
                MiCloudConnectCode: {
                    ClientId: syncSettings.find(x => x.Key === 'SignInClientId').Value,
                    Value: code
                }
            };
        } else {
            return {};
        }
    }
}
