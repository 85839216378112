import React, { Component } from 'react';
import {
    cmsGetPromise
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ExpandingReactTable from '../ExpandingReactTable';
import { connect } from 'react-redux';

var _ = require('lodash');
var moment = require('moment-timezone');

class AccountTrials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptions: null,
            tableRows: [],
            pageCount: 0,
            loading: true,
            page: 0,
        };

        this.getSubscriptions = this.getSubscriptions.bind(this);
    }

    getSubscriptions(state, instance) {
        var self = this;

        var apiParams = {
            accountId: this.props.account.Id,
            objectType: 'targetsubscriptions',
            State: 'Active',
            IsTrial: 'true',
            IncludeChildAccounts: 'true',
            SortBy: 'end',
            SortDirection: 'Descending'
        };

        // Handle sorting/order
        //apiParams['SortBy'] = 'FirstName';
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'account') {
                apiParams['SortBy'] = 'TargetAccountName';
            }
            if (state.sorted[0].id === 'end') {
                apiParams['SortBy'] = 'ExpiryDate';
            }
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGetPromise(apiParams).then(
            function (data) {
                var allSubs = data.data.Results;

                var tableRows = [];

                allSubs.forEach(function (entry) {

                    var accountName = null;
                    if (entry.TargetAccountName && entry.TargetAccountId) {
                        accountName = <Link key="account" to={"/portal/" + entry.TargetAccountId + '/users'}>{entry.TargetAccountName}</Link>;
                    }
                    var date = entry.UpgradeDate ? 'Converts on ' + moment.tz(entry.UpgradeDate, "UTC").format("DD MMMM YYYY") : entry.ExpiryDate ? 'Expires on ' + moment.tz(entry.ExpiryDate, "UTC").format("DD MMMM YYYY") : '';

                    var rowData = {
                        account: accountName,
                        plan: entry.Description ? entry.Description : entry.PlanName,
                        quantity: entry.Quantity ? entry.Quantity : '',
                        end: date
                    };
                    tableRows.push(rowData);
                })


                self.setState({
                    subscriptions: allSubs,
                    tableRows: tableRows,
                    pageCount: data.data.PageCount,
                    loading: false
                });
            }, function (error) {
                toast.error("Unable to retrieve account trials");
                console.log(error);
                self.setState({
                    loading: false
                });
            }
        );
    }

    render() {
        var self = this;

        const columns = [{
            id: 'account',
            Header: () => <div style={{ textAlign: "left" }}>Account</div>,
            accessor: "account",
            sort: 'desc',
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }, {
            id: 'plan',
            Header: () => <div style={{ textAlign: "left" }}>Plan</div>,
            accessor: "plan",
            sortable: false,
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }, {
            id: 'quantity',
            Header: () => <div style={{ textAlign: "left" }}>Quantity</div>,
            accessor: "quantity",
            sortable: false,
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }, {
            id: 'end',
            Header: () => <div style={{ textAlign: "left" }}>Trial End Date</div>,
            accessor: "end",
            sort: 'desc',
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }] 

        return (
            <ExpandingReactTable
                data={self.state.tableRows}
                manual
                columns={columns}
                defaultPageSize={10}
                multiSort={true}
                updateData={self.getSubscriptions}
                defaultSorted={[{
                    id: 'end',
                    desc: true,
                }]}
                noDataText={"You do not currently have any trials"}
                loading={this.state.loading}
                loadingText={"Loading..."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                minRows={0}
                showPageJump={false}
                page={this.state.page}
                pages={this.state.pageCount}
                onPageChange={(newPage) => {
                    self.setState({ page: newPage });
                }}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
            />
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountTrials);