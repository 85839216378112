import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import React from 'react';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import servicesReducer from './reducers/services'; 
import accountReducer from './reducers/account';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    services: servicesReducer,
    account: accountReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));
export default store;

export const StoreProvider = (props) => {

    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    );
}