import React, { Component } from 'react';
import MicrosoftServicePanel from './MicrosoftServicePanel';
import PBXServicePanel from './PBXServicePanel';
import TrunkServicePanel from './TrunkServicePanel';
import { serviceCodeToIconClass, isSystemOwnerSystemAcc, isSystemOwner, canAccess } from '../CallMSUIHelpers.js';
import {
    extractLicence,
    SFB_DOMAIN_LICENCE_CODE,
    PBX_USER_LICENCE_CODE,
    TRUNK_USER_LICENCE_CODE
} from '../LicenceHelpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ServiceSync from './ServiceSync';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ServiceAudit from './ServiceAudit';

var _ = require('lodash');

class ServiceTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableRevision: 1,
            tabIndex: 0,
        };
        this.updateTab = this.updateTab.bind(this);
    }

    componentDidMount() {
        this.handleTargetTab();
    }

    componentDidUpdate(prevProps) {
        if (
            (      this.props.match.params.serviceName
                && prevProps.match.params.serviceName
                && (this.props.match.params.serviceName !== prevProps.match.params.serviceName)
            )
            ||
            (
                this.props.services.totalList !== prevProps.services.totalList
            )
            ||
            (
                this.props.services.list !== prevProps.services.list
            )
        ) {
            console.log("Tab target update detected");
            this.handleTargetTab();
        }
    }

    handleTargetTab = () => {
        let targetTab = this.props.match.params.serviceName;

        if (!targetTab) {
            let pbxLic = extractLicence(this.props.account, PBX_USER_LICENCE_CODE);
            let trunkLic = extractLicence(this.props.account, TRUNK_USER_LICENCE_CODE);
            if (trunkLic && trunkLic.NumPurchased && (!pbxLic || pbxLic.NumPurchased === 0)) {
                targetTab = 'trunk';
            }
        }

        if (targetTab) {
            var newTabIndex = this.getIndexFromTabKey(targetTab, this.getTabsInner(this.props.services.totalList, this.props.services.list));
            if (typeof(newTabIndex) !== "undefined" && newTabIndex !== null && this.state.tabIndex !== newTabIndex) {
                this.setState({ tabIndex: newTabIndex });
            }
        }
    }

    /**
     * Update current tab, also try update URL with tab key (often service or
     * service variant code) for any future page reloads.
     * 
     * @param {any} index
     */
    updateTab(index) {
        var tab = this.getTabFromIndex(index);
        if (tab && !this.props.disableChangeUrlWithTab && !this.props.restrictServiceTabs) {
            this.props.history.push("/portal/" + this.props.account.Id + "/services/" + tab.key);
        }

        this.setState({ tabIndex: index });
    }

    getTabFromIndex(index) {
        var all = this.getTabs();
        return all && all.Headers && all.Headers[index] ? all.Headers[index] : null;
    }

    getIndexFromTabKey(key, all) {
        if (all && all.Headers) {
            var i;
            for (i = 0; i < all.Headers.length; i++) {
                var h = all.Headers[i];
                if (h.key === key) {
                    return i;
                }
            }
        }
    }

    getTabs() {
        return this.getTabsInner(
            this.props.services.totalList, this.props.services.list
        );
    }

    getTabsInner(totalServiceList, serviceList) {
        var self = this;
        var tabHeaders = [];
        var tabPanels = [];

        totalServiceList.forEach(function (sId) {
            var availableService = _.find(self.props.account.AvailableServices, function (el) { return el.Id === sId });
            var firstExisting = null;
            var existingServices = [];
            if (serviceList[sId]) {
                existingServices = serviceList[sId];
                firstExisting = existingServices[0];
            }

            var s = null;
            if (availableService) {
                s = availableService;
            } else if (firstExisting) {
                s = {
                    Id: firstExisting.ServiceId,
                    Code: firstExisting.ServiceCode,
                    Name: firstExisting.Name
                }
            }

            if (!s) {
                return;
            }

            if ((s.Code === 'teams' || s.Code === 'sfb' || s.Code === 'sfbfed') && !self.props.restrictServiceTabs) {
                var pri = s.Code === 'sfbfed' ? 3 : s.Code === 'sfb' ? 4 : 5;

                let isAvailable = true;
                let content = (
                    <MicrosoftServicePanel
                        availableService={availableService}
                        currentService={firstExisting}
                        showDocuments={true}
                    />
                );

                if (s.Code === 'sfb') {
                    let lics = extractLicence(self.props.account, SFB_DOMAIN_LICENCE_CODE);
                    if (!lics || !lics.NumPurchased) {
                        isAvailable = false;
                        content = (
                            <div className="alert alert-warning">
                                <p>You do not have any SfB Domain Licences available for this service.</p>
                            </div>
                        );
                    }
                }

                tabHeaders.push(
                    <Tab viewpriority={pri} key={s.Code}>
                        <span className={isAvailable ? "" : "unavailable-service-tab"}>
                            <i className={serviceCodeToIconClass(s.Code)}></i> {s.Name}
                        </span>
                    </Tab>
                );

                tabPanels.push(
                    <TabPanel viewpriority={pri} key={s.Id}>
                        {content}
                    </TabPanel>
                );
            } else if (s.Code === 'pbx') {
                /*
                 * PBX is a little different as each variant is given a tab
                 */
                var pbxVar = null;
                var trunkVar = null;
                if (availableService && availableService.hasOwnProperty('Variants')) {
                    pbxVar = _.find(availableService.Variants, function (v) { return v.Code === 'PBX' });
                    trunkVar = _.find(availableService.Variants, function (v) { return v.Code === 'TRUNK' });
                }

                var emergencyTrunkFeature = self.props.account
                    && self.props.account.hasOwnProperty('Features')
                    && self.props.account.Features.length > 0
                    && _.find(self.props.account.Features, x => _.includes('EMERGENCYTRUNK', x));

                var existingServicesPbx = [];
                existingServicesPbx = _.filter(existingServices, function (e) { return e.ServiceVariantCode === 'PBX' });
                var existingServicesTrunk = [];
                existingServicesTrunk = _.filter(existingServices, function (e) { return e.ServiceVariantCode === 'TRUNK' });


                if (pbxVar || existingServicesPbx.length) {
                    let isAvailable = true;
                    let warning = null;

                    let lics = extractLicence(self.props.account, PBX_USER_LICENCE_CODE);
                    if ((!lics || !lics.NumPurchased) && !self.props.restrictServiceTabs) {
                        isAvailable = false;
                        warning = (
                            <div className="alert alert-warning">
                                <p>You do not have any PBX User Licences available for this service.</p>
                            </div>
                        );
                    }

                    if (!self.props.restrictServiceTabs
                        || (self.props.restrictServiceTabs && lics && lics.NumPurchased)) {
                        let content = (
                            <PBXServicePanel
                                availableService={availableService}
                                availableServiceVariant={pbxVar}
                                currentServices={existingServicesPbx}
                                allowNewService={isAvailable}
                                restrictServiceTabs={self.props.restrictServiceTabs}
                                saveServiceCallback={self.props.saveServiceCallback}
                                skipTeamsTrueTransferCheck={self.props.skipTeamsTrueTransferCheck}
                                skipAutoSync={self.props.skipAutoSync}
                                skipSyncCallback={self.props.skipSyncCallback}
                                limitToOneService={self.props.limitToOneService}
                                {...self.props}
                            />
                        );

                        tabHeaders.push(
                            <Tab viewpriority={1} key="pbx">
                                <span className={isAvailable ? "" : "unavailable-service-tab"}>
                                    <i className={serviceCodeToIconClass(s.Code)}></i> PBX
                            </span>
                            </Tab>
                        );
                        tabPanels.push(
                            <TabPanel viewpriority={1} key="PBX_Panel">
                                {warning}
                                {content}
                            </TabPanel>
                        );
                    }
                }

                if (emergencyTrunkFeature || trunkVar || existingServicesTrunk.length) {
                    let isAvailable = true;
                    let warning = null;
                    let lics = extractLicence(self.props.account, TRUNK_USER_LICENCE_CODE);
                    if ( (!lics || !lics.NumPurchased) && !emergencyTrunkFeature) {
                        isAvailable = false;
                        warning = (
                            <div className="alert alert-warning">
                                <p>You do not have any Trunk User Licences available for this service.</p>
                            </div>
                        );
                    }
                    if (!self.props.restrictServiceTabs
                        || emergencyTrunkFeature
                        || (self.props.restrictServiceTabs && lics && lics.NumPurchased)) {

                        let content = (
                            <TrunkServicePanel
                                updateServicesTrigger={self.props.triggerServicesUpdate}
                                availableService={availableService}
                                availableServiceVariant={trunkVar}
                                currentServices={existingServicesTrunk}
                                allowNewService={isAvailable}
                                restrictServiceTabs={self.props.restrictServiceTabs}
                                saveServiceCallback={self.props.saveServiceCallback}
                                limitToOneService={self.props.limitToOneService}
                                {...self.props}
                            />
                        );

                        tabHeaders.push(
                            <Tab viewpriority={2} key="trunk">
                                <span className={isAvailable ? "" : "unavailable-service-tab"}>
                                    <i className="fa-solid fa-globe"></i> Trunk
                            </span>
                            </Tab>
                        );
                        tabPanels.push(
                            <TabPanel viewpriority={2} key="TRUNK_Panel">
                                {warning}
                                {content}
                            </TabPanel>
                        );
                    }
                }
            } else {
                if (!self.props.restrictServiceTabs) {
                    tabPanels.push(
                        <TabPanel key={s.Id}>
                            The requested service is not supported.
                            Please contact your
                        {self.props.account.Brand} administrator.
                    </TabPanel>
                    );
                }
            }
        });

        if (canAccess('ServiceAudit', this.props.baseAccount.roles)) {
            tabHeaders.push(
                <Tab viewpriority={6} key="audit">
                    <span>
                        <i className='fa-solid fa-clock-rotate-left'></i> Audit
                    </span>
                </Tab>
            );
            tabPanels.push(
                <TabPanel viewpriority={6} key="audit_panel">
                    <ServiceAudit />
                </TabPanel>
            );
        }

        tabHeaders = _.sortBy(tabHeaders, function (h) { return h.props.viewpriority; });
        tabPanels = _.sortBy(tabPanels, function (h) { return h.props.viewpriority; });

        return {
            Headers: tabHeaders,
            Panels: tabPanels
        };
    }

    render() {
        var tabWrapper = null;
        var loading = null;
        var self = this;

        if (!self.props.services.loaded) {
            loading = <p>Loading account services...</p>

        } else {
            if (self.props.services.totalList.length === 0) {
                loading = (
                    <div className="alert alert-info">
                        <p>There are currently no services available on your account. Please contact your administrator.</p>
                    </div>
                );

            } else {
                var tabs = self.getTabs();
                var tabHeaders = tabs.Headers;
                var tabPanels = tabs.Panels;

                tabWrapper = (
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={self.updateTab}>
                        <TabList>
                            {tabHeaders}
                        </TabList>

                        {tabPanels}
                    </Tabs>
                );
            }

        }

        var serviceSync = null;
        if (self.props.services.rawList.length) {
            serviceSync = <ServiceSync />;
        }

        return (
            <>
                {loading}
                {tabWrapper}
            </>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        services,
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
       
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceTabs));
