import React, { Component } from 'react';
import ExpandingReactTable from '../ExpandingReactTable';
import { cmsGetServicesHistoryPromise } from '../CallMSAPI';
import { getUserName } from '../MSALAuthProvider';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ServiceAuditFormWrapper from './ServiceAuditFormWrapper';
var moment = require('moment-timezone');

class ServiceAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UPN: null
        };
    }
    componentDidMount() {
        let self = this;
        let username = getUserName();
        self.setState({ UPN: username });
    }
    render() {
        var self = this;
        if (self.state.UPN) {
            return (
                <>
                    <AuditHistoryReactTable account={self.props.account} UPN={self.state.UPN} />
                </>
            );
        } else {
            return null
        }
        
    }
}

class AuditHistoryReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},
        };
        this.updateData = this.updateData.bind(this);
        this.tableRef = React.createRef();
        this.handleRowExpanded = this.handleRowExpanded.bind(this);
        this.closeAllRows = this.closeAllRows.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            console.log("Starting an update");
            self.tableRef.current.forceTableUpdate();
        }
    }
    updateData(state, instance) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        var apiParams = {
            actionId: null,
            fromDate: null,
            toDate: null,
        };

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGetServicesHistoryPromise(self.props.account.account.Id, apiParams).then((resp) => {
            let data = resp.data;
            self.setState({
                data: data.Results,
                pageCount: data.PageCount,
                loading: false,
                sortedResults: []
            });
        }).catch((err => {
            toast.error('Error fetching audit logs: ' + err);
        }));

    }
    handleRowExpanded = (newExpanded, index, event) => {
        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
            });

            // Mark a refresh in progress to stop updates
            this.props.setRefreshPause && this.props.setRefreshPause(true);
        }
    }
    closeAllRows = () => {
        // Ditch all expanded rows...
        this.setState({
            expanded: {},
        });
    }
    render() {
        var self = this;
        const data = this.state.data;

        return (
            <div>
                <h4>Service Updates</h4>
                <ExpandingReactTable
                    data={data}
                    pages={this.state.pageCount}
                    expanded={this.state.expanded}
                    onExpandedChange={this.handleRowExpanded}
                    resizable={false}
                    filterable
                    columns={[
                        {
                            id: "name",
                            Header: "Service Name",
                            accessor: function (d) {
                                let name = "";
                                if (d.AfterData) {
                                    name = JSON.parse(d.AfterData).Name;
                                } else if (d.BeforeData) {
                                    name = JSON.parse(d.BeforeData).Name;
                                }
                                return name;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "modifiedBy",
                            Header: "Modified By",
                            accessor: function (d) {
                                //return d.records[d.records.length - 1].ModifiedBy;
                                return d.UserPrincipalName;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "auditType",
                            Header: "Action",
                            accessor: function (d) {
                                //let record = d.records[d.records.length - 1]
                                return d.AuditAction.Name;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "modifiedon",
                            Header: "Modified On",
                            accessor: function (d) {

                                let t = moment.tz(d.Timestamp, "UTC");
                                if (t.isValid()) {
                                    if (t.year() === moment().year()) {
                                        return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                    } else {
                                        return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                    }
                                }
                            },
                            sortable: false,
                            filterable: false
                        }
                    ]
                    }
                    defaultSorted={[{
                        id: 'name',
                        desc: true,
                    }]}
                    minRows={0}
                    showPageJump={false}
                    updateData={self.updateData}
                    multiSort={false}
                    loading={this.state.loading}
                    loadingText={"Loading..."}
                    noDataText={"You don't have any Service History."}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    pageSizeOptions={[10, 20, 50]}
                    className="-striped -highlight"
                    // Tell react-table that we'll be sorting pagination and sorting via server side
                    manual
                    // Important to allow manual updating
                    ref={this.tableRef}
                    SubComponent={row => {
                        let service = row.original
                        
                        return (
                            <ServiceAuditFormWrapper prevService={service.BeforeData ? JSON.parse(service.BeforeData) : null} service={service.AfterData ? JSON.parse(service.AfterData) : null} AuditAction={service.AuditAction.Name} />
                        );
                    }}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceAudit);