import axios from 'axios';
import { get, post, put } from 'axios';
import { msalApiFetch, getIdToken, getSyncIdToken, getSyncTokens, getScopedSyncTokens } from './MSALAuthProvider';
import {
    getExternalSyncFormattedStartData,
    getExternalSyncToken
} from './ExternalAuthProvider';
import LocalStorageHelper from './utils/LocalStorageHelper';
import { toast } from 'react-toastify';
import { generateDemoCallDataUser, generateDemoCallDataWrapper, expandCallData, expandJsonToCallLog } from './CallMSUIHelpers';
 
require('es6-shim');
var _ = require('lodash');
var allSettled = require('promise.allsettled');

//let devUrl = 'http://localhost:37728/v1/';
let devUrl = 'https://admin.qa.call2.team/api/v1/';
if (process && process.env && process.env.REACT_APP_DMS_URL) {
    devUrl = process.env.REACT_APP_DMS_URL;
}

let productionUrl = window.location.origin + '/api/v1/';
var $cacheCountryLookup = {};

export function getBaseUrl() {
    var base = productionUrl;
    if (window.location.hostname === 'localhost') {
        base = devUrl;
    }

    //console.log("Using base API: " + base);
    return base;
}

export function getO365TenantLocalStorage(oid) {
    return LocalStorageHelper.getWithExpiry(`cms_${oid}`);
}

export function setO365TenantLocalStorage(oid, value) {
    LocalStorageHelper.setWithExpiry(`cms_${oid}`, (60 * 60 * 24 * 365), value);
}

export function getLastAzureAccountLocalStorage() {
    return LocalStorageHelper.getWithExpiry('cmsLastAccount');
}

export function clearLastAzureAccountLocalStorage() {
    LocalStorageHelper.removeItem('cmsLastAccount');
}

export const clearLocalStorage = (keyArr = [''], callBack = () => { }) => {

    const arr = LocalStorageHelper.filter(x => {
        const index = keyArr.findIndex(c => x.key.includes(c));
        return index >= 0;
    });

    LocalStorageHelper.clear();

    arr.forEach(x => {
        callBack(x);
    });
}

export function setLastAzureAccountLocalStorage() {
    var token = getIdToken();

    if (token && token.hasOwnProperty('preferred_username')) {
        // Use preferred username for next time they log-in...
        console.log("Storing cmsLastAccount: " + token.preferred_username);
        LocalStorageHelper.setWithExpiry('cmsLastAccount', (60 * 60 * 24 * 365), token.preferred_username)

    } else {
        console.log("Skipping cmsLastAccount, no valid token.");
    }
}

export function cmsGetUnmappedCalls(accId, callback, errorCb) {
    var p = cmsGetUnmappedCallsPromise(accId);
    p.then(
        function (result) {
            callback && callback(result.data);
        },
        function (error) {
            errorCb && errorCb(error);
        }
    );
}

export function cmsGetUnmappedCallsPromise(accId) {
    return cmsGetPromise(
        {
            'accountId': accId,
            'objectType': 'unmappedcalls',
            'sortBy': 'CreatedOn',
            'sortDirection': 'Descending',
            //'pageSize': '0'
        }
    );
}

export function cmsGetContractTypes(accId) {
    return cmsGetPromise(
        {
            'accountId': accId,
            'objectType': 'contracttypes'
        }
    );
}

export function cmsGetContractCurrencies(accId, callback, errorCb) {
    var url = getBaseUrl() + 'accounts/' + accId + '/availablecurrencies'
    msalApiFetch(get, url, { params: { sortBy: 'Name' } })
        .then(function (response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function (error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });
}

export function cmsGetContractSupplierBrands(accId, callback, errorCb) {
    var url = getBaseUrl() + 'accounts/' + accId + '/availablesupplierbrands'
    msalApiFetch(get, url, { params: { sortBy: 'Name' } })
        .then(function (response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function (error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });
}
export function cmsGetAppSettings() {
    var url = getBaseUrl() + '/appsettings';
    return msalApiFetch(get, url);
}
export function cmsGetPhoneAppSettings() {
    var url = getBaseUrl() + '/appsettings?clientType=phoneapp';
    return msalApiFetch(get, url);
}
export function cmsRecordConsentGranted(accId, accServiceId, applicationType) {
    let postData = {};
    postData["accountId"] = accId;
    postData['objectIds'] = [
        { type: 'services', id: accServiceId },
        { type: 'consentgranted' }
    ];
    postData["ApplicationType"] = applicationType;
    return cmsPostPromise(postData);
}
export function cmsGetSyncModuleDefaultSettings(accId) {
    var url = getBaseUrl() + 'accounts/' + accId + '/syncmoduledefaultsettings?SyncModuleCode=Teams';
    return msalApiFetch(get, url);
}

export function cmsGetPrivateAccountAppSettings(accId) {
    var url = getBaseUrl() + 'accounts/' + accId + '/appsettings'
    return msalApiFetch(get, url)
}

export function cmsGetBaseAccountAppSettings(accId, callback, errorCb) {
    var url = getBaseUrl() + 'accounts/' + accId + '/appsettings'
    msalApiFetch(get, url)
        .then(function (response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function (error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });
}

export function cmsGetAccountPrivateData(accId, targetAccId, callback, errorCb) {
    var url = getBaseUrl() + 'accounts/' + accId + '/privatedata/' + targetAccId;
    msalApiFetch(get, url)
        .then(function (response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function (error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });
}

export function cmsPutAccountPrivateData(baseAccntId, tarAccId, putData, okCb, errorCb) {
    putData['accountId'] = baseAccntId;
    putData['objectIds'] = [
        { type: 'privatedata', id: tarAccId }
    ];
    cmsPut(putData, function (userData) {
        okCb && okCb(userData);
    }, function (error, info) {
        errorCb && errorCb(getErrMsg(error));
    }
    );
}

export function cmsPutMigrateAccountSBC(accId, serviceId, okCb, errorCb) {
    var url = getBaseUrl() + 'accounts/' + accId + '/migrate_sbcs/' + serviceId;
    var putData = {};
    putData["accountId"] = accId;
    putData["APIPrefix"] = "system";
    putData["queryString"] = "DeallocateSBCMode=AllowDeallocate";
    putData['objectIds'] = [
        { type: 'migrate_sbcs', id: serviceId }
    ];
    cmsPut(putData, function (userData) {
        okCb && okCb(userData);
    }, function (error, info) {
        errorCb && errorCb(getErrMsg(error));
    }
    );
}

export function cmsGetBaseAccounts(callback, errorCb) {
    msalApiFetch(get, getBaseUrl() + 'accounts', {params: {sortBy: 'Name'}})
        .then(function(response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function(error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });
}

export function cmsGetSystemSubscriptionOffers(currencyCode, callback, errorCb) {
    msalApiFetch(get, getBaseUrl() + 'subscriptionoffers', {params: {pageSize: '999', currencyCode: currencyCode}})
        .then(function(response) {
            if (response.data) {
                callback && callback(response.data);
            } else {
                callback && callback();
            }
        })
        .catch(function(error) {
            if (error) {
                errorCb && errorCb(getErrMsg(error));
            } else {
                errorCb && errorCb("Unknown error");
            }
        });

}

export function cmsGet(params, callback, errorCb) {
    var result = cmsParamsToUri(params);

    if (result.hasOwnProperty('error')) {
        errorCb && errorCb(result.error);
        return;
    }

    params = result.data;
    var url = result.url

    msalApiFetch(
        get, url, {params: params}
    ).then(function(response) {
        if (response.data) {
            callback && callback(response.data);
        } else {
            callback && callback(response);
        }
    }).catch(function(error) {
        if (error) {
            errorCb && errorCb(error);
        }
    });
}

export function cmsGetHistoricalData(params) {
    return cmsGetPromise(params);
}

export function cmsGetPromise(params) {
    var result = cmsParamsToUri(params);

    params = result.data;
    var url = result.url

    return msalApiFetch(
        get, url, { params: params }
    );
}

export function cmsPostPromise(data) {
    var result = cmsParamsToUri(data);

    if (result.hasOwnProperty('error')) {
        return;
    }

    data = result.data;
    var url = result.url

    return msalApiFetch(
        post, url, {}, data
    ).then(function (response) {
        if (response.data) {
            return response.data;
        } else {
            return;
        }
    });
}

export function cmsPost(data, callback, errorCb) {
    var result = cmsParamsToUri(data);

    if (result.hasOwnProperty('error')) {
        errorCb && errorCb(result.error);
        return;
    }

    data = result.data;
    var url = result.url

    return msalApiFetch(
        post, url, {}, data
    ).then(function(response) {
        if (response.data) {
            callback && callback(response.data);
        } else {
            callback && callback();
        }
    }).catch(function(error) {
        if (error) {
            errorCb && errorCb(error);
        }
    });
}

export function cmsGetPublic(params, callback, errorCb) {
    var url = getBaseUrl() + params.objectType;
    get(url)
    .then(function(response) {
        if (response.data) {
            callback && callback(response.data);
        } else {
            callback && callback();
        }
    })
    .catch(function(error) {
        if (error) {
            errorCb && errorCb(error);
        }
    });
}

export function cmsParamsToUri(data) {
    if (data.hasOwnProperty('objectIds') && data.objectIds.length) {
        var first = data['objectIds'].shift();
        data['objectId'] = first.id;
        data['objectType'] = first.type;
    }

    if (data.hasOwnProperty('objectId') && !data.hasOwnProperty('objectType')) {
        return {error: "Missing objectType parameter"};
    }

    var skipAccount = false;
    if (data.hasOwnProperty('objectType') && data.objectType === 'admininvitations') {
        // This could be a special case token accept...
        if (data.hasOwnProperty('objectIds') && data.objectIds.length === 1 && data.objectIds[0].type === 'accept') {
            skipAccount = true;
        }
    } else if (data.hasOwnProperty('objectType') && data.objectType === 'system/branding/brands') {
        skipAccount = true;
    }

    var url = getBaseUrl();
    if (data.hasOwnProperty('APIPrefix')) {
        url += data.APIPrefix + '/';
        delete data.APIPrefix;
    }
    if (skipAccount === false) {
        if (!data.hasOwnProperty('accountId')) {
            return {error: "Missing accountId parameter"};
        }

        url += 'accounts/' + data.accountId;
        delete data.accountId;
    }
    
    if (data.hasOwnProperty('objectType')) {
        url += '/' + data.objectType;
        delete data.objectType;
    }

    if (data.hasOwnProperty('objectId')) {
        url += '/' + data.objectId;
        delete data.objectId;
    }

    if (data.hasOwnProperty('baseAccountId')) {
        url += '/' + data.baseAccountId;
        delete data.baseAccountId;
    }

    // Any remaining objectId pairs will now need adding...
    if (data.hasOwnProperty('objectIds') && data.objectIds.length) {
        for(var i = 0; i < data.objectIds.length; i++) {
            var current = data.objectIds[i];

            if (current.hasOwnProperty('objectId') && !current.hasOwnProperty('objectType')) {
                return {error: "Missing objectType parameter"};
            }

            url += '/' + current.type;
            if (current.id !== undefined) {
                url += '/' + current.id;
            }
        }

        delete data.objectIds;
    }

    if (data.hasOwnProperty('queryString')) {
        url += '?' + data.queryString;
        delete data.queryString;
    }

    return {
        data:   data,
        url:    url
    }
}

export function cmsTempAuthRedirect(accId) {
    var postData = {};
    postData["accountId"] = accId;
    postData['objectType'] = 'temptoken';
    postData['objectId'] = 'generate';

    return cmsPostPromise(postData);
}

export function cmsDelete(data, okCb, errorCb) {
    var result = cmsParamsToUri(data);

    if (result.hasOwnProperty('error')) {
        errorCb && errorCb(result.error);
        return;
    }

    data = result.data;
    var url = result.url;

    // Make delete request here...
    msalApiFetch(
        axios.delete, url, data
    ).then(function(response) {
        if (response.data) {
            okCb && okCb(response.data);
        } else {
            okCb && okCb();
        }
    }).catch(function(error) {
        if (error) {
            errorCb && errorCb(error);
        }
    });
}

export function cmsDeletePromise(data) {
    var result = cmsParamsToUri(data);

    if (result.hasOwnProperty('error')) {
        throw new Error(result.error);
    }

    data = result.data;
    var url = result.url;

    // Make delete request here...
    return msalApiFetch( axios.delete, url, data );
}

export function cmsPutPromise(data) {
    var result = cmsParamsToUri(data);

    data = result.data;
    var url = result.url;

    return msalApiFetch(put, url, {}, data);
}

export function cmsPut(data, callback, errorCb) {
    var result = cmsParamsToUri(data);

    if (result.hasOwnProperty('error')) {
        errorCb && errorCb(result.error);
        return;
    }

    data = result.data;
    var url = result.url;

    msalApiFetch(
        put, url, {}, data
    ).then(function(response) {
        if (response.data) {
            callback && callback(response.data);
        } else {
            callback && callback();
        }
    }).catch(function(error) {
        if (error) {
            errorCb && errorCb(error);
        }
    });
}

export function cmsDeleteAccount(accId, okCb, errorCb) {
    cmsDelete(
        { accountId: accId },
        function (ok) {
            okCb && okCb(ok);
        },
        function (error) {
            errorCb && errorCb("Unable to delete account: " + getErrMsg(error));
        }
    );
}

export function cmsAddAdminInvite (values, okCb, errorCb) {
    var postData = values;

    // API subs out defaulthost for correct brand
    postData['AcceptURI'] = 'https://defaulthost/accept';
    postData['objectType'] = 'admininvitations';

    cmsPost(postData, function (userData) {
            okCb && okCb(userData);
        }, function (error, info) {
            errorCb && errorCb("Unable to create admin invite: " + getErrMsg(error));
        }
    );
}

export function cmsAcceptToken(token, okCb, errorCb) {
    var putData = [];

    putData['objectIds'] = [
        {type: 'admininvitations', id: token},
        {type: 'accept'}
    ]

    cmsPut(putData, function (userData) {
            okCb && okCb(userData);
        }, function (error, info) {
            errorCb && errorCb(getErrMsg(error));
        }
    );
}

export function cmsGetSubscriptionOffers(accId, okCb, errorCb) {
    var values = {
        accountId: accId,
        objectType: 'subscriptionoffers',
        IncludeInheritedOffers: true,
        sortBy: 'PlanName',
        sortDirection: 'Descending'
    };

    cmsGet(values, okCb, errorCb);
}

export function cmsGetUsers(accId, values, okCb, errorCb) {
    values['accountId'] = accId;
    values['objectType'] = 'serviceusers';
    cmsGet(values, okCb, errorCb);
}

export function cmsAddServiceUser(accId, postData, okCb, errorCb) {
    var promise = cmsAddServiceUserPromise(accId, postData);
    promise.then(
        function (response) {
            okCb && okCb(response);
        }, function (error, info) {
            errorCb && errorCb("Unable to add user: " + getErrMsg(error));
        }
    );
}

export function cmsAddServiceUserPartsPromise(accId, serId, uploadMode, inputArray) {

    var postData = {};
    postData["accountId"] = accId;
    postData['objectIds'] = [
        { type: 'services', id: serId },
        { type: 'serviceuserpartslist' }
    ];
    postData['IsExternallyManaged'] = true;
    postData['Items'] = _.cloneDeep(inputArray);
    postData['CreateOrModify'] = uploadMode;

    return cmsPostPromise(postData);
}

export function cmsAddServiceUserPromise(accId, inputPostData) {
    var postData = _.cloneDeep(inputPostData);

    // Ditch any supplied sync user part objects for the underlying Ids
    ['Part1', 'Part2'].forEach(function (part) {
        if (postData[part] && postData[part].SyncUserPart) {
            postData[part + 'Id'] = postData[part].SyncUserPart.Id
            delete postData[part];
        }
    });

    postData["accountId"] = accId;
    postData["ExternalId"] = null;
    postData["AllowTo1"] = true;
    postData["AllowTo2"] = true;
    postData["AllowEditInUI"] = true;

    postData['objectType'] = 'serviceusers';
    return cmsPostPromise(postData);
}

export function cmsEditServiceUserPromise(accId, userId, postData) {
    postData["accountId"] = accId;
    postData['objectIds'] = [
        { type: 'serviceusers', id: userId }
    ];
    return cmsPutPromise(postData);
}

export function cmsUpdateAdminUserPromise(accId, userId, putData) {
    putData["accountId"] = accId;
    putData['objectIds'] = [
        { type: 'adminusers', id: userId }
    ];
    return cmsPutPromise(putData);
}

export function cmsGetAllAdminUsersHistoryPromise(params) {
    return cmsGetPromise(params);
}

export function cmsGetAdminUsers(accId) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectType'] = 'adminusers';

    return cmsGetPromise(postData);
}

export function cmsGetAdminUserRoleHistoryPromise(apiParams) {

    var url = getBaseUrl() + 'accounts/' + apiParams.baseAccountId + '/adminusers/' + apiParams.adminUserId + '/rolehistory/';
    delete apiParams.adminUserId;
    return msalApiFetch(
        get, url, { params: apiParams }
    );
}

export function cmsUpdateAPIRolesPromise(accId, apiId, putData) {
    putData["accountId"] = accId;
    putData['objectIds'] = [
        { type: 'apikeys', id: apiId }
    ];
    return cmsPutPromise(putData);
}

export function cmsEditServiceUserPartPromise(accId, accServiceId, userId, putData) {
    var filteredPutData = {};

    /**
     * TODO: Conder a refactor for a central data sanitize point (would need to know full account service as well)
     */
    if (putData.SyncUserPart) {
        // expected case: Teams sync'd part (pos. PBX sync part)
        console.log("Sync'd ServiceUserPart update limited to just PhoneNumber and CallingPolicy");
        filteredPutData['CallingPolicy'] = putData.CallingPolicy ? putData.CallingPolicy : null;
        filteredPutData['PhoneNumber'] = putData.PhoneNumber;
    } else {
        // expected case: PBX non-sync'd, plus everything else
        filteredPutData = putData;
    }

    filteredPutData["accountId"] = accId;
    filteredPutData['objectIds'] = [
        { type: 'services', id: accServiceId },
        { type: 'serviceuserparts', id: userId }
    ];

    return cmsPutPromise(filteredPutData);
}


export function cmsAddAccount(postData, okCb, errorCb) {
    postData['objectType'] = 'accounts';

    cmsPost(postData, function (userData) {
            okCb && okCb(userData);
        }, function (error) {
            errorCb && errorCb("Unable to add account: " + getErrMsg(error));
        }
    );
}

export function cmsEditAccount(putData, okCb, errorCb) {
    cmsPut(putData, function (userData) {
            okCb && okCb(userData);
        }, function (error) {
            errorCb && errorCb("Unable to edit account: " + getErrMsg(error));
        }
    );
}

export function cmsEditAccountPromise(putData) {
    return cmsPutPromise(putData)
}

export function cmsAddDomain(values, okCb, errorCb) {
    // Pull out service IDs to enable for later API calls...
    // Slice to make a 'copy'
    let domainServices = values['services'].slice(0);
    delete values['services'];

    // Grab accountId for later use adding services...
    let accountId = values['accountId'];

    // Start by adding the domain
    values['objectType'] = 'domains';
    cmsPost(values, function(domainData) {
            if (domainServices.length > 0) {
                var resultCount = 0;
                domainServices.forEach(function(serviceInfo) {
                    var serviceParams = {
                        accountId: accountId,
                        objectIds: [
                            {type: 'domains', id: domainData.Id},
                            {type: 'service', id: serviceInfo.Id}
                        ],
                        regionId: serviceInfo.RegionId
                    };

                    cmsPut(serviceParams, function(serviceResult) {
                        resultCount++;
                        if (resultCount === domainServices.length) {
                            okCb && okCb(domainData);
                        }
                    }, function(error) {
                        errorCb && errorCb("Unable to add service to domain: " + getErrMsg(error));
                    });
                });
            } else {
                okCb && okCb(domainData);
            }

        }, function(error) {
            // TODO: Error handling, respond error.Message to user.
            errorCb && errorCb("Unable to add domain: " + getErrMsg(error));
        }
    );
}

export function cmsAddService(accId, values, okCb, errorCb) {
    values['accountId'] = accId;
    if (values.Domain) {
        values.Domains = [ values.Domain ];
        delete values.Domain;
    }

    if (values.RegionId) {
        values.RegionIds = [ values.RegionId ];
    }
    delete values.RegionId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    values['objectType'] = 'services';
    cmsPost(values,
        function (serviceData) {
            okCb && okCb(serviceData);
        }, function(error) {
            // TODO: Error handling, respond error.Message to user.
            errorCb && errorCb("Unable to add service: " + getErrMsg(error));
        }
    );
}

export function cmsAddServicePromise(accId, values) {
    values['accountId'] = accId;
    if (values.Domain) {
        values.Domains = [ values.Domain ];
        delete values.Domain;
    }

    if (values.RegionId) {
        values.RegionIds = [ values.RegionId ];
    }
    delete values.RegionId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    values['objectType'] = 'services';
    return cmsPostPromise(values);
}

export function cmsGetFullService(accId, accountServiceId) {
    var postData = {};
    postData['accountId'] = accId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    postData['objectType'] = 'services';
    postData['objectId'] = accountServiceId;

    return cmsGetPromise(postData);
}

export function cmsEditService(accId, accountServiceId, values, okCb, errorCb) {
    values['accountId'] = accId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    values['objectType'] = 'services';
    values['objectId'] = accountServiceId;
    cmsPut(values,
        function (serviceData) {
            okCb && okCb(serviceData);
        }, function (error) {
            // TODO: Error handling, respond error.Message to user.
            errorCb && errorCb("Unable to edit service: " + getErrMsg(error));
        }
    );
}

export function cmsEditServicePromise(accId, accountServiceId, values, okCb, errorCb) {
    values['accountId'] = accId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    values['objectType'] = 'services';
    values['objectId'] = accountServiceId;
    return cmsPutPromise(values);
}

export async function cmsEditServiceAsync(accId, accountServiceId, values) {
    values['accountId'] = accId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    values['objectType'] = 'services';
    values['objectId'] = accountServiceId;
    return await cmsPutPromise(values);
}

export function cmsUpdateService(accId, objId, values, okCb, errorCb) {
    if (values.Domain) {
        values.Domains = [ values.Domain ];
        delete values.Domain;
    }

    if (values.RegionId) {
        values.RegionIds = [ values.RegionId ];
    }
    delete values.RegionId;

    values['accountId'] = accId;
    values['objectType'] = 'services';
    values['objectId'] = objId;

    // Needs a ServiceVariantId ServiceId, RegionIds, Domains, ExternalServiceAccountId, AllowEditInUI
    cmsPut(values,
        function (serviceData) {
            okCb && okCb(serviceData);
        }, function(error) {
            // TODO: Error handling, respond error.Message to user.
            errorCb && errorCb("Unable to update service: " + getErrMsg(error));
        }
    );
}

export function cmsGetServiceSyncJob(accId, Id, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'syncjobs',
        objectId: Id
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}
export function cmsGetServiceSyncJobs(accId, data, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'syncjobs'
    };

    cmsGet(
        _.merge(apiParams, data),
        okCb,
        errorCb
    );
}

export function cmsGetLatestServiceSyncJobs(accId, serviceId = null) {
    var apiParams = {
        accountId: accId,
        objectType: 'syncjobs',
        objectId: 'latest',
    };

    if (serviceId !== null) {
        apiParams['AccountServiceId'] = serviceId;
    }

    return cmsGetPromise( apiParams );
}

export function cmsGetTrunkStatus(accId, serviceId) {
    var apiParams = {
        accountId: accId,
        objectIds: [
            {
                type: 'services',
                id: serviceId
            },
            {
                type: 'registrationstatus'
            }
        ]
    };

    return cmsGetPromise( apiParams );
}

export function cmsGetPBXStatus(accId, serviceId, userPartId) {
    var apiParams = {
        accountId: accId,
        objectIds: [
            {
                type: 'services',
                id: serviceId
            },
            {
                type: 'serviceuserparts',
                id: userPartId 
            },
            {
                type: 'registrationstatus'
            }
        ]
    };

    return cmsGetPromise( apiParams );
}

export function cmsGetApiKeysPromise(apiParams) {
    apiParams['objectType'] = 'apikeys';

    return cmsGetPromise( apiParams );
}

export function cmsGetSingleServiceUserPartsPromise(accId, accServiceId, singleId, opts) {
    if (opts === undefined) {
        opts = {};
    }

    opts['accountId'] = accId;
    opts['objectIds'] = [
        {
            type: 'services',
            id: accServiceId
        },
        {
            type: 'serviceuserparts',
            id: singleId
        }
    ];

    if (!opts['SortBy']) {
        opts['SortBy'] = 'NameOrIdentifier';
    }

    return cmsGetPromise( opts );
}

export function cmsGetServiceUserPartsPromise(accId, accServiceId, opts) {
    opts['accountId'] = accId;
    opts['objectIds'] = [
        {
            type: 'services',
            id: accServiceId
        },
        {
            type: 'serviceuserparts'
        }
    ];

    if (!opts['SortBy']) {
        opts['SortBy'] = 'NameOrIdentifier';
    }

    return cmsGetPromise( opts );
}

export function cmsServiceMigrate(accId, serviceId, values, okCb, errorCb) {
    values['accountId'] = accId;
    values['objectIds'] = [
        {
            type: 'services',
            id: serviceId
        },
        {
            type: 'migrate'
        }
    ];

    cmsPut(values, okCb, errorCb);
}

export function cmsServiceEnableSyncWrapper(accId, serviceObj, values, popup, okCb, errorCb) {

    cmsGetSyncModuleDefaultSettings(accId).then(function (res) {
        var tenantId = res.data.find(x => x.Key === "TenantId").Value;
        getSyncIdToken(false, true, popup, tenantId)
            .then(function (ok) {
                var tid = ok.tid;
                var username = ok.preferred_username;
                var domainParts = username.split('@');

                if (!values.hasOwnProperty('SyncSettings')) {
                    values['SyncSettings'] = {};
                }
                values['SyncSettings']['TenantId'] = tid;
                values['SyncSettings']['TenantDomain'] = domainParts[domainParts.length - 1];

            }, function (err) {
                errorCb && errorCb(err);
            }).finally(function () {
                cmsServiceEnableSync(accId, serviceObj.Id, values, okCb, errorCb);
            });
    });
}

export function cmsServiceEnableSync(accId, serviceId, values, okCb, errorCb) {
    values['accountId'] = accId;
    values['objectIds'] = [
        {
            type: 'services',
            id: serviceId
        },
        {
            type: 'sync'
        }
    ];

    cmsPut(values, okCb, errorCb);
}

export function cmsServiceDisableSync(accId, serviceId, okCb, errorCb) {
    var data = {
        accountId: accId,
        objectIds: [
            {
                type: 'services',
                id: serviceId
            },
            {
                type: 'sync'
            }
        ]
    }

    cmsDelete(data, okCb, errorCb);
}

export function cmsStartServiceSyncPromise(accId, data) {
    return msalApiFetch(post, `${getBaseUrl()}accounts/${accId}/services/sync`, null, data);
}

export function cmsSetGraphConsentedOn(accId) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectType'] = 'accountserviceteams/setgraphconsentedon';
    return cmsPutPromise(postData);
}

export function cmsSetGraphFailedOn(accId) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectType'] = 'accountserviceteams/setgraphfailedon';
    return cmsPutPromise(postData);
}

export function cmsSetPresenceConsentedOn(accId, serviceId, appId, code) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectIds'] = [
        {
            type: 'accountserviceteams',
            id: serviceId
        },
        {
            type: 'presence'
        },
        {
            type: 'apps',
            id: appId
        },
        {
            type: 'setpresenceconsentedon'
        }
    ];
    var redirectUri = window.location.origin + '/authDummy.html';
    var redirectUriComp = encodeURIComponent(redirectUri);

    var result = cmsParamsToUri(postData);
    var data = result.data;
    var url = result.url;
    url += '?authCode=' + code + '&redirectURI=' + redirectUriComp;

    return msalApiFetch(put, url, {}, data);
}

export function cmsTogglePresence(accId, serviceId, presenceToggle) {
    let apiParams = {};
    apiParams["accountId"] = accId;
    apiParams['objectIds'] = [
        { type: 'accountserviceteams', id: serviceId },
        { type: 'presence/setstatus', id: presenceToggle }
    ];
    return cmsPostPromise(apiParams);
}

export function cmsSetPresenceFailedOn(accId, serviceId, appId) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectIds'] = [
        {
            type: 'accountserviceteams',
            id: serviceId
        },
        {
            type: 'presence'
        },
        {
            type: 'apps',
            id: appId
        },
        {
            type: 'setpresencefailedon'
        }
    ];
    return cmsPutPromise(postData);
}

export function cmsStartAllServiceSyncPromise(accId, postData) {
    postData['accountId'] = accId;
    postData['objectType'] = 'services/sync';

    return cmsPostPromise(postData);

}

export function cmsStartServiceSync(accId, data, okCb = () => { }, errorCb = () => { }) {
    return msalApiFetch(post, `${getBaseUrl()}accounts/${accId}/services/sync`, null, data)
        .then(resp => {
            okCb(resp.data);
        }).catch(err => {
            errorCb(`Unabled to start sync: ${getErrMsg(err)}`);
        });
}

export function cmsStartOAuthServiceSync(accId, serviceObj, userSettings = {}, popup, syncClientID, isAutoSync) {
    var opts = {
        shouldCaptureDebug: false,
        promptUser: false,
        firstSync: false
    };

    _.merge(opts, userSettings);

    // Step 1, grab id token
    // If not first time, we might have a domain hint...
    var domainHint = null;
    var syncTenantId = null;
    var appSyncId = null;
    if (serviceObj.SyncSettings) {
        var syncTenant = _.find(serviceObj.SyncSettings, { Key: 'TenantDomain' });
        if (syncTenant) {
            domainHint = syncTenant.Value;
            console.log("Using domain hint of " + domainHint);
        }
        var tenantId = _.find(serviceObj.SyncSettings, { Key: 'TenantId' });
        if (tenantId && tenantId.Value) {
            syncTenantId = tenantId.Value;
        }
        var syncId = _.find(serviceObj.SyncSettings, { Key: 'SyncClientId' });
        if (syncId && syncId.Value) {
            appSyncId = syncId.Value;
        }
    } 
    if (!appSyncId && syncClientID) {
        appSyncId = syncClientID;
    }

    //If phoneapp is enabled and at least one toggle is switched on then use v2 version of auth and use extra scopes
    var useV2Auth = false;
    if (serviceObj && serviceObj.TeamsSettings) {
        if (serviceObj.TeamsSettings.IsPhoneAppEnabled && serviceObj.TeamsSettings.IsPhoneAppEnabled != 'Disabled') {
            useV2Auth = true;
        }
    }
    var extraScopes = [
        'AppCatalog.ReadWrite.All',
        'TeamsAppInstallation.ReadWriteForUser'
    ];

    var promise = getSyncIdToken(domainHint, opts['promptUser'], popup, syncTenantId)
        .then(function (ok) {
            var tid = ok.tid;
            var username = ok.preferred_username;

            if (serviceObj.SyncSettings) {
                var syncTenant = _.find(serviceObj.SyncSettings, { Key: 'TenantId' });
                if (syncTenant && syncTenant.Value && syncTenant.Value !== tid) {
                    // tenants don't match!
                    // let upstream provider deal with closing popup
                    throw new Error("The tenant for the selected user does not match the previously tenant selected.");
                } else {
                    console.log("Tenants match so we are all good!");
                }
            } else {
                console.log("No tenant values to check with");
            }

            if (serviceObj.ServiceCode === 'sfb') {
                console.log("Using scoped v2 URI");
                return getScopedSyncTokens(1, tid, username, popup, appSyncId);
            } else if (useV2Auth) {
                return getScopedSyncTokens(1, tid, username, popup, appSyncId, extraScopes);
            } else {
                console.log("Using unscoped v1 URI");
                return getSyncTokens(1, tid, username, popup, appSyncId);
            }
        }
    );

    // Step 2, does it match the expected tenant ID?
    return promise.then(function (results) {
        if (results.length < 1) {
            throw new Error("Not enough codes returned");
        }
        if (!results[0].code ) {
            throw new Error("Sync response but missing code(s)");
        }

        return cmsGetPrivateAccountAppSettings(accId).then(res => {   
                if(res && res.data) {
                    let teamsAuth = {
                        type: isAutoSync ? "TenantId" : 'AuthorizationCode',
                        value: isAutoSync ? syncTenantId: results[0].code,
                        clientId: isAutoSync ? res.data.AutoSyncApplicationId : results[0].client_id
                    };
                    if(!isAutoSync) {
                        teamsAuth.redirectUri = results[0].redirect_uri;
                    }
                    
                    return cmsStartServiceSyncPromise(
                        accId,
                        {
                            AccountServiceIds: [serviceObj.Id],
                            auth: {
                                teams: teamsAuth
                            },
                            CaptureDebug: opts['shouldCaptureDebug'],
                            IsFirstSync: opts['firstSync']
                        }
                    );
                }
            });
        })


}

export function cmsStartExternalServiceSync(accId, serviceObj, userSettings = {}, popup) {
    var opts = {
        shouldCaptureDebug: false,
        promptUser: false,
        firstSync: false
    };
    _.merge(opts, userSettings);

    // Note the clone here so we can add service name without issue below
    var syncSettings = serviceObj.hasOwnProperty('SyncSettings') ? _.cloneDeep(serviceObj.SyncSettings) : null;

    // inject the account service name for display in custom popups (e.g. Kazoo)
    if (syncSettings && serviceObj) {
        syncSettings.push({
            Value: serviceObj.Name,
            Key: 'AccountServiceName'
        })
    }

    var promise = getExternalSyncToken(serviceObj.ServiceSyncModuleCode, popup, syncSettings);
    return promise.then(function (results) {
        var code = '';
        if (results && results.length === 1 && results[0].code) {
            code = results[0].code;
        }

        var prereqPromises = [];

        // Kazoo is a special case where first time they might set an API URI which we need to store in sync settings
        if (serviceObj.ServiceSyncModuleCode.startsWith('KAZOO')) {
            var startingApi = '';
            var startingDeviceType = '';
            var startingDeviceNamePrefix = '';
            var startingincludeUnassignedDevices = '';
            if (syncSettings) {
                var apiUriVal = syncSettings.find(x => x.Key === 'APIURI');
                var deviceTypeVal = syncSettings.find(x => x.Key === 'DeviceType');
                var deviceNamePrefixVal = syncSettings.find(x => x.Key === 'DeviceNamePrefix');
                var includeUnassignedDevicesVal = syncSettings.find(x => x.Key === 'IncludeUnassignedDevices');
                if (apiUriVal) {
                    startingApi = apiUriVal.Value;
                }
                if (deviceTypeVal) {
                    startingDeviceType = deviceTypeVal.Value;
                }
                if (deviceNamePrefixVal) {
                    startingDeviceNamePrefix = deviceNamePrefixVal.Value;
                }
                if (includeUnassignedDevicesVal) {
                    startingincludeUnassignedDevices = includeUnassignedDevicesVal.Value;
                }
            }

            var newApi = results[0] && results[0].apiuri ? results[0].apiuri : '';
            var deviceType = results[0] && results[0].deviceType ? results[0].deviceType : '';
            var deviceNamePrefix = results[0] && results[0].deviceNamePrefix ? results[0].deviceNamePrefix : '';
            var includeUnassignedDevices = results[0] && results[0].includeUnassignedDevices ? results[0].includeUnassignedDevices : '';
            var updateSyncSettings = false;
            var mergedSyncOb = {
                'APIURI': null,
                'DeviceType': null,
                'DeviceNamePrefix': null,
                'IncludeUnassignedDevices': null
            };

            if (newApi && newApi.length && newApi !== startingApi) {
                updateSyncSettings = true;
                mergedSyncOb['APIURI'] = newApi;
            } else {
                delete mergedSyncOb['APIURI'];
            }
            if (deviceType && deviceType.length && deviceType !== startingDeviceType) {
                updateSyncSettings = true;
                mergedSyncOb['DeviceType'] = deviceType;
            } else {
                delete mergedSyncOb['DeviceType'];
            }
            if (deviceNamePrefix && deviceNamePrefix.length && deviceNamePrefix !== startingDeviceNamePrefix) {
                updateSyncSettings = true;
                mergedSyncOb['DeviceNamePrefix'] = deviceNamePrefix;
            } else {
                delete mergedSyncOb['DeviceNamePrefix'];
            }
            if (includeUnassignedDevices && includeUnassignedDevices.length && includeUnassignedDevices !== startingincludeUnassignedDevices) {
                updateSyncSettings = true;
                mergedSyncOb['IncludeUnassignedDevices'] = includeUnassignedDevices;
            } else {
                delete mergedSyncOb['IncludeUnassignedDevices'];
            }
            if (updateSyncSettings) {

                var syncsettingsOb = {
                    'OverwriteData': true,
                    'SyncSettings': mergedSyncOb 
                };

                prereqPromises.push(cmsEditServiceAsync(accId, serviceObj.Id, syncsettingsOb))
            } 
        }

        return Promise.all(prereqPromises).then(function (res) {

            var outCodeData = getExternalSyncFormattedStartData(serviceObj.ServiceSyncModuleCode, code, syncSettings);

            return cmsStartServiceSyncPromise(
                accId,
                {
                    AccountServiceIds: [serviceObj.Id],
                    auth: outCodeData,
                    CaptureDebug: opts['shouldCaptureDebug'],
                    IsFirstSync: opts['firstSync']
                }
            );
        })
    });
}

export function cmsGetCountries(accId, data, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'countries',
        sortBy: 'Name'
    };

    cmsGet(
        _.merge(apiParams, data),
        okCb,
        errorCb
    );
}

export function cmsGetCountriesPromise(accId, data) {

    if (data && data.hasOwnProperty('countryId') && $cacheCountryLookup[accId + '_' + data.countryId]) {
        var data = $cacheCountryLookup[accId + '_' + data.countryId];
        data['IsCached'] = 'Y';
        return Promise.resolve({
            data: {
                Results: [ data ]
            }
        });
    }
    else {

        var apiParams = {
            accountId: accId,
            objectType: 'countries',
            sortBy: 'AccountCountryThenName',
            //pageSize: '1000000000000'
        };

        return cmsGetPromise(
            _.merge(apiParams, data)
        ).then(res => {
            if (res.data.Results) {
                // Store each country in a lookup cache
                // for single country requests
                res.data.Results.forEach(function (ob) {
                    $cacheCountryLookup[accId + '_' + ob.Id] = ob;
                });
            }
            return res;
        });
    }
}

export function cmsGetStatePromise(accId, data) {
    var apiParams = {
        accountId: accId,
        objectType: 'states',
        sortBy: 'AccountStateThenName'
    };

    return cmsGetPromise(
        _.merge(apiParams, data)
    );
}

export function cmsGetPBXTemplates(accId, svId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'pbxtemplates',
        ServiceVariantId: svId,
        sortBy: 'Name'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsGetPBXTemplatePromise(accId, pbxId) {
    var apiParams = {
        accountId: accId,
        objectType: 'pbxtemplates',
        objectId: pbxId
    };
    return cmsGetPromise(apiParams);
}

export const getEmergencyServicesOptionsPromise = (accId = '') => {
    return getEmergencyServicesPromise(accId).then((res) => {
        return res.map(s => ({ Name: s.Name, Id: s.Id }));
    });
}

export const getEmergencyServicesPromise = (accId = '') => {
    return cmsGetServicesPromise(accId).then(res => {
        if (res.data) {
            if (res.data.Results) {
                return res.data.Results.filter(x => x.Emergency);
            }
        }
        return [];
    }).catch(err => toastErrHandler(err));
}

export function cmsGetPBXTemplate(accId, pbxId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'pbxtemplates',
        objectId: pbxId
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsGetPBXTemplateHistory(accId, pbxId, okCb, errorCb) {
    var historicalTemplateData = {};
    historicalTemplateData['accountId'] = accId;
    historicalTemplateData['objectIds'] = [
        {
            type: 'pbxtemplates',
            id: pbxId
        },
        {
            type: 'history'
        }
    ];

    cmsGetHistoricalData(historicalTemplateData).then(function (res) {
        okCb && okCb(res)
    }).catch(function (err) {
        errorCb && errorCb(err)
    })     
}

export function cmsGetRoles(accId, okCb, errorCb) {
    // TEMP work around until we get our own roles
    return cmsGetAdminInvitationRoles(accId, okCb, errorCb);
}

export function cmsGetAdminInvitationRoles(accId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'admininvitationroles'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsGetChildAccountsPromise(accId, limit = null, search = null) {
    var apiParams = {
        accountId: accId,
        objectType: 'accounts',
        pageSize: 9999,
        accountScope: 'TopAndDescendants'
    };

    if (limit !== null) {
        apiParams['pageSize'] = limit;
    }

    if (search !== null) {
        apiParams['searchText'] = search;
    }

    return cmsGetPromise( apiParams );
}

export function cmsGetServicesPromise(accId) {
    var apiParams = {
        accountId: accId,
        objectType: 'services'
    };

    return cmsGetPromise( apiParams );
}

export function cmsGetAccountServicePromise(accId, sId) {
    var apiParams = {
        accountId: accId,
        objectType: 'services',
        objectId: sId
    };

    return cmsGetPromise( apiParams );
}

export function cmsGetAllAccountServicePromise(accId, services) {
    // services is result from accounts/ID/services
    // need to cycle through and get the results for everything...
    var sRequests = [];
    _.forEach(services, function (s) {
        sRequests.push(cmsGetAccountServicePromise(accId, s.Id));
    })

    return Promise.all(sRequests).then(function (results) {
        var out = [];
        _.forEach(results, function (r) {
            out.push(r.data);
        });
        return out;
    });
}

export function cmsGetServices(accId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'services',
        pageSize: 999
    };

    var p = cmsGetPromise(apiParams);
    p.then(function (resp) {
        okCb(resp.data)
    }, errorCb );
}


export function cmsGetLegacyServices(accId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'legacyservices',
        sortBy: 'Name'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsGetDomains(accId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'domains'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function getErrMsg(error) {
    if (error === undefined || !error) {
        return '';
    }
    if (error.hasOwnProperty('response')
        && error.response !== undefined
        && error.response.hasOwnProperty('data')
        && error.response.data.hasOwnProperty('Message')
    ) {
        return error.response.data.Message;
    } else if (error && error.hasOwnProperty('message')) {
        return error.message;
    } else {
        // Auto convert to string will use message
        return error;
    }
}

export function cmsDeleteUserPart(accountId, accServiceId, userPartId, okCb, errorCb) {

    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectIds'] = [
        {
            type: 'services',
            id: accServiceId
        },
        {
            type: 'serviceuserparts',
            id: userPartId
        }
    ];

    cmsDelete(deleteObj, okCb, errorCb);
}

export function cmsDeleteAdminUserPromise(accountId, adminUserId) {
    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectIds'] = [
        {
            type: 'adminusers',
            id: adminUserId
        }
    ];

    return cmsDeletePromise(deleteObj);
}

export function cmsDeleteAdminInvitePromise(accountId, inviteId) {
    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectIds'] = [
        {
            type: 'admininvitations',
            id: inviteId 
        }
    ];

    return cmsDeletePromise(deleteObj);
}

export function cmsDeleteAPIKeyPromise(accountId, apiKeyId) {
    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectIds'] = [
        {
            type: 'apikeys',
            id: apiKeyId 
        }
    ];

    return cmsDeletePromise(deleteObj);
}

export function cmsAddAPIKeyPromise(values) {
    var postData = values;
    postData['objectType'] = 'apikeys';
    return cmsPostPromise(postData);
}

export function cmsDeleteUser(accountId, userId, okCb, errorCb) {

    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectType'] = 'serviceusers';
    deleteObj['objectId'] = userId;

    cmsDelete(deleteObj, okCb, errorCb);
}

export function cmsToggleDisableUser(accountId, user, okCb, errorCb) {
    var userId = user.Id;

    var newState = 'Disabled';
    if (user.LicenceState === 'Disabled') {
        newState = 'Licenced';
    }

    var disableObj = {};
    disableObj['accountId'] = accountId;
    disableObj['objectType'] = 'serviceusers';
    disableObj['objectId'] = userId;
    disableObj['LicenceState'] = newState;

    cmsPut(disableObj, okCb, errorCb);
}

export function cmsDeleteService(accountId, serviceId, okCb, errorCb) {

    var deleteObj = {};
    deleteObj['accountId'] = accountId;
    deleteObj['objectType'] = 'services';
    deleteObj['objectId'] = serviceId;

    cmsDelete(deleteObj, okCb, errorCb);
}

export function extractNewSubscriptionData(offerIds, values) {
    var changes = [];
    offerIds.forEach(function (i) {
        if (values.hasOwnProperty('quantity-' + i)) {
            var q = values['quantity-' + i];
            q = parseInt(q);
            delete values['quantity-' + i];

            if (q > 0) {
                changes.push({
                    "SubscriptionOfferId": i,
                    "Quantity": q
                });
            }
        }
    })
    return changes;
}

export function getBaseOrderInfo(baseId, targetId, orderRef = null) {
    var output = {
        "accountId": baseId,
        "objectType": "orders",
        "TargetAccountId": targetId,
        // API will set this for us.
        // "ExternalUserId": getUserName(),
    };

    if (orderRef !== null) {
        output["OrderReference"] = orderRef;
    }

    return output;
}

export function cmsEditTemplate(accountId, templateId, values, okCb, errorCb) {
    var putData = values;
    putData['objectType'] = 'pbxtemplates';
    putData['accountId'] = accountId;
    putData['objectId'] = templateId;

    cmsPut(putData, function (response) {
        okCb && okCb(response);
    }, function (error, info) {
        errorCb && errorCb("Unable to update template: " + getErrMsg(error));
    });
}

export function cmsAddTemplate(accountId, values, okCb, errorCb) {
    var postData = values;
    postData['objectType'] = 'pbxtemplates';
    postData['accountId'] = accountId;

    cmsPost(postData, function (response) {
        okCb && okCb(response);
    }, function (error, info) {
        errorCb && errorCb("Unable to add template: " + getErrMsg(error));
    });
}

export function cmsDeleteTemplate(accountId, templateId, okCb, errorCb) {
    var deleteData = {};
    deleteData['objectType'] = 'pbxtemplates';
    deleteData['accountId'] = accountId;
    deleteData['objectId'] = templateId;

    cmsDeletePromise(deleteData).then(
        function (response) {
        okCb && okCb(response);
    }).catch(function (error, info) {
        errorCb && errorCb("Unable to delete template: " + getErrMsg(error));
    });
}

export function cmsUploadCompanyLogo(accountId, values, okCb, errorCb) {
    var postData = values;
    postData['objectType'] = "branding/logoImage";
    postData["accountId"] = accountId;

    cmsPost(postData, function (response) {
        okCb && okCb(response);
    }, function (error) {
        errorCb && errorCb("Unable to upload image: " + getErrMsg(error));
    });
}

export function cmsDeleteCompanyLogo(accountId, okCb, errorCb) {
    var deleteData = {};
    deleteData['accountId'] = accountId;
    deleteData['objectType'] = "branding/logoImage";

    cmsDelete(deleteData, function (response) {
        okCb && okCb(response);
    }, function (error) {
        errorCb && errorCb("Unable to delete image: " + getErrMsg(error));
    });
}

export function cmsDownloadCompanyLogo(accountId, okCb, errorCb) {
    var apiParams = {
        accountId: accountId,
        objectType: 'branding/logoImage'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsSetPhoneAppBrandingIcon(accId, data) {
    var postData = data;
    postData['objectType'] = "branding/phoneapp";
    postData["accountId"] = accId;

    return cmsPostPromise(postData);
}

export function cmsSetPhoneAppBranding(accId, data) {
    var postData = data;
    postData['objectType'] = "branding/phoneapp";
    postData["accountId"] = accId;

    return cmsPostPromise(postData);
}

export function cmsGetPhoneAppBranding(accId) {
    var apiParams = {
        accountId: accId,
        objectType: 'branding/phoneapp'
    };
    return cmsGetPromise(apiParams);
}

export function cmsDeletePhoneAppBrandingIcon(accId, type) {
    var apiParams = {
        accountId: accId,
        objectType: 'branding/phoneAppLogoImage',
        imageExternalLinkType: type
    };

    var result = cmsParamsToUri(apiParams);

    if (result.hasOwnProperty('error')) {
        throw new Error(result.error);
    }

    let data = result.data;
    let url = result.url + '?imageExternalLinkType=' + type;

    // Make delete request here...
    return msalApiFetch(axios.delete, url, data);
}

export function cmsGetExternalLinks(accId, okCb, errorCb) {
    var apiParams = {
        accountId: accId,
        objectType: 'externallinks'
    };

    cmsGet(
        apiParams,
        okCb,
        errorCb
    );
}

export function cmsEditExternalLink(accountId, externalLinkId, values, okCb, errorCb) {
    var putData = values;
    putData['objectType'] = 'externallinks';
    putData['accountId'] = accountId;
    putData['objectId'] = externalLinkId;

    cmsPut(putData, function (response) {
        okCb && okCb(response);
    }, function (error, info) {
        errorCb && errorCb(getErrMsg(error));
    });
}

export function cmsAddExternalLink(accountId, values, okCb, errorCb) {
    var postData = values;
    postData['objectType'] = 'externallinks';
    postData['accountId'] = accountId;

    cmsPost(postData, function (response) {
        okCb && okCb(response);
    }, function (error) {
        errorCb && errorCb(getErrMsg(error));
    });
}

export function cmsDeleteExternalLink(accountId, externalLinkId, okCb, errorCb) {
    var deleteData = {};
    deleteData['objectType'] = "externallinks";
    deleteData['accountId'] = accountId;
    deleteData['objectId'] = externalLinkId;

    cmsDelete(deleteData, function (response) {
        okCb && okCb(response);
    }, function (error) {
        errorCb && errorCb(getErrMsg(error));
    });
}

export const cmsEnableAutoSync = (accId, serviceId, tenantId) => {
    let data = {};
    
    data['accountId'] = accId;
    data['objectIds'] = [
        {
            type: 'services',
            id: serviceId
        },
        {
            type: 'autosync/enable'
        }
    ];
    data['tenantId'] = tenantId;
    return cmsPostPromise(data);
}

export const cmsDisableAutoSync = (accId, serviceId, tenantId) => {
    let data = {};
    
    data['accountId'] = accId;
    data['objectIds'] = [
        {
            type: 'services',
            id: serviceId
        },
        {
            type: 'autosync/disable'
        }
    ];
    data['tenantId'] = tenantId;
    return cmsPostPromise(data);
}

export const cmsGetCallData = (accountId = '', callId = '', respCallBack = () => { }, errCallBack = (err = new Error()) => { }) => {
    if (accountId && accountId.length == 0) {
        errCallBack(new Error('Error, unidentified account.'),0);
        return;
    }
    if (callId && callId.length == 0) {
        errCallBack(new Error('Error, unidentified call log.'),0);
        return;
    }

    if (callId.includes('demo_')) {
        let newURL = window.location.protocol + "//" + window.location.host + "/" + "demo/" + callId + ".json.log";

        // We _might_ have local SIP files as well, try grab them here.
        // If they don't exist we'll gracefully ignore the 404
        let newSIPURL = window.location.protocol + "//" + window.location.host + "/" + "demo/" + callId + ".json.public.sip.log";
        let newPrivateSIPURL = window.location.protocol + "//" + window.location.host + "/" + "demo/" + callId + ".json.private.sip.log";

        let demoData = generateDemoCallDataWrapper(callId, newURL, newSIPURL, newPrivateSIPURL);

        handleCallLogData(demoData)
            .then(resp => generateDemoCallDataUser(resp, respCallBack))
            .catch(err => errCallBack(err));
    } else {
        cmsGet(
            {
                'accountId': accountId,
                'objectType': 'calls',
                'objectId': callId
            },
            resp => {
                if (resp) {
                    let extraDataCalls = [];
                    if (resp.ServiceUserId) {
                        extraDataCalls.push(cmsGetPromise({
                            'accountId': accountId,
                            'objectType': 'serviceusers',
                            'objectId': resp.ServiceUserId
                        }));
                    }

                    Promise.all(extraDataCalls).then(resps => {
                        var userRawData = resps[0];
                        const data = {
                            callLogData: expandCallData(resp),
                            callLogUser: userRawData.data,
                        };

                        respCallBack(data);

                        handleCallLogData(data)
                            .then(resp => respCallBack({ ...resp}))
                            .catch(err => errCallBack(err));

                    }).catch(err => {
                        errCallBack(err)
                    });

                } else {
                    respCallBack({ callLogData: false });
                }
            },
            err => {
                console.error(err);
                errCallBack(err);
            }
        );
    }
}
export function cmsGetWizardPrevPreReqsPromise(accId, data) {
    var data = {};
    data = data;
    data['accountId'] = accId;
    data['objectType'] = 'eventLogs';

    return cmsGetPromise(data);
}
export function cmsPutWizardPrevPreReqsPromise(accId, data) {
    var postData = {};
    postData['accountId'] = accId;
    postData['objectType'] = 'eventLog';
    postData['Log'] = data.results;
    postData['tenantId'] = data.tenantId;
    postData['userUPN'] = data.userUPN;
    return cmsPostPromise(postData);
}


/**
 * Handle call log data when fetching from URL 
 * @param {any} data from cmsGetCallData
 */
const handleCallLogData = (data = {}) => {
    // no op if not required
    allSettled.shim();

    return new Promise((res, rej) => {
        if (!data.callLogData) {
            return rej(new Error('Call log data is unavailable.'));
        }
        if (data.callLogData.DebugOutput && !data.callLogData.DebugOutputURL) {
            return res(data);
        }
        if (!data.callLogData.DebugOutputURL) {
            return rej(new Error('No debug output available for call.'));
        }
        var sRequests = [];
        sRequests.push( axios.get(data.callLogData.DebugOutputURL) );

        if (data.callLogData.DebugOutputPublicSIPURL) {
            sRequests.push( axios.get( data.callLogData.DebugOutputPublicSIPURL ) );

        }

        /**
         * If call log data is available for the internal part of the call
         * (edge<-->DR, call ID Internal), then let's keep track of that
         * separately.
         */
        let privateReqIndex = null;
        if (data.callLogData.DebugOutputPrivateSIPURL) {
            sRequests.push( axios.get( data.callLogData.DebugOutputPrivateSIPURL ) );
            privateReqIndex = sRequests.length - 1;
        }

        Promise.allSettled(sRequests).then(function (results) {
            var out = [];
            var privateOut = [];
            _.forEach(results, function (resp, ind) {
                try {
                    if (resp.status !== 'fulfilled') {
                        // Likely fine, we expect legacy SBCs to not have the newer files
                        console.log(resp.reason.toString());
                    } else {
                        // Don't use .data, it may be text, may be an object, we want to
                        // always treat it as text.
                        if (ind == privateReqIndex) {
                            privateOut.push(resp.value.request.responseText.replaceAll("\n{", ",{"));
                        } else {
                            out.push(resp.value.request.responseText.replaceAll("\n{", ",{"));
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            });

            if (out.length) {
                data.callLogData = expandJsonToCallLog(
                    data.callLogData,
                    JSON.parse( '[' + out.join(',') + ']' ),
                    (privateOut.length ? JSON.parse( '[' + privateOut.join(',') + ']' ) : []),
                );
            }

            res(data);
        }).catch(e => rej(e));
    });
}

export const toastErrHandler = (err = new Error()) => toast.error(`Error: ${getErrMsg(err)}`);

export const reallocateServiceSbcs = (
    accId = '',
    accServiceId = '',
    deallocateSBCMode = '',
    overrideSbcs = ['']
) => {
    let queryString = `DeallocateSBCMode=${deallocateSBCMode}`;

    if (overrideSbcs != null && overrideSbcs.length > 0) {
        queryString += `&OverrideSBCs=${overrideSbcs.join('&OverrideSBCs=')}`;
    }

    let putData = {
        accountId: accId,
        queryString: queryString,
        objectIds: [
            {
                type: 'services',
                id: accServiceId
            },
            {
                type: 'reallocate'
            }
        ]
    };
    return cmsPutPromise(putData);
}
export function cmsGetServicesHistoryPromise(accountId, apiParams) {

    var url = getBaseUrl();
    url += `audit/${accountId}/AccountService`;

    return msalApiFetch(
        get, url, { params: apiParams }
    );
}
export function cmsGetServiceHistoriesPromise(accountId, baseAccount, accountServiceId, historyId) {
    var params = {};
    params['accountId'] = accountId;
    params['baseAccount'] = baseAccount;
    params['accountServiceId'] = accountServiceId;
    params['historyId'] = historyId;
    params['objectIds'] = [
        {
            type: 'services',
            id: accountServiceId
        },
        {
            type: 'history',
        }
    ];

    return cmsGetPromise(params);
}