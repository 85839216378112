import React, { Component } from 'react';
import { Field, Formik, ErrorMessage } from 'formik';
import { getPbxLabel } from '../CallMSUIHelpers.js'
import ServiceUserPartForm from './ServiceUserPartForm';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { SubmitButton } from '../FormHelpers';
import { ServicesQuery } from '../Services/ServiceFormHelpers.js';
import { cmsGetPBXTemplatePromise } from '../CallMSAPI';
import { connect } from 'react-redux';

var _ = require('lodash');

/*
 * Use a PureComponent to ensure unnecessary redraws are avoided (e.g. no-op
 * prop/state changes).
 */
class ServiceUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: {
                Part1: _.filter(this.props.services.rawList, function (o) { return o.ServiceCode === 'pbx'; }),
                Part2: _.filter(this.props.services.rawList, function (o) { return o.ServiceCode !== 'pbx'; }),
            },
            deleteIsSubmitting: false
        };

        // Need 'setState' from event handler
        this.getFormDefaults = this.getFormDefaults.bind(this);
        this.serviceDropdown = this.serviceDropdown.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({ hideDialog: false });
    }

    hideModal() {
        this.setState({ hideDialog: true });
    }

    convertToFauxService(sumService) {
        return {
            Id: sumService.Id,
            Name: sumService.Name,
            CountryId: null,
            StateId: null,
            Domains: [],
            PBXSettings: {},
            SBCs: [],
            SyncSettings: [],
            TeamsSettings: null,
            TrunkSettings: null,
            Variant: {
                Code: sumService.ServiceVariantCode,
                Id: sumService.ServiceVariantId,
                ServiceCode: sumService.ServiceCode,
                ServiceId: sumService.ServiceId,
                ServiceName: sumService.ServiceName
            },
            SyncEnabled: sumService.SyncEnabled,
            SyncRequired: sumService.SyncRequired,
            SyncRequiresPerJobToken: sumService.SyncRequiresPerJobToken,
        };
    }

    getFormDefaults() {
        var baseDefaults = {
            Part1: { // PBX
                "AccountServiceId": 0, // so Id of AccountService call
                "Identifier": "",
                "DomainId": "", // service will have a Domains entry
                "AuthUsername": "",
                "Password": "",
                "hasPassword": false,
                "Name": "",
                //"Email": "",
                "PhoneNumber": "",
                //"ExternalId": "",
                "ServiceNumberBehaviourTypeId": null,
                "AccountServiceTrunkServiceNumberId": null,
                "s1": null
            },
            Part2: {
                "AccountServiceId": 0,
                "Identifier": "",
                "DomainId": "",
                //"Name": "Test Part 2",
                "CountryId": (this.props.account.CountryId ? this.props.account.CountryId : ''),
                "StateId": (this.props.account.StateId ? this.props.account.StateId : ''),
                // "Email": "",
                // "PhoneNumber": "",
                // "ExternalId": "",
                //"AuthUsername": "",
                //"Password": "",
                "DefaultCallingPolicy": [],
                "VMCallingPolicy": "",
                "MOHCallingPolicy": "",
                "s2": null,
                "PhoneNumberExtension": "",
                "ShowExtension": 'Hide'
            }
        };

        // We are editing, not a new form!
        if (this.props.user) {
            if (this.props.user.Part1) {
                baseDefaults['Part1'] = {
                    "Id": this.props.user.Part1.Id,
                    "AccountServiceId": this.props.user.Part1.AccountServiceId,
                    "Identifier": this.props.user.Part1.Identifier,
                    "DomainId": this.props.user.Part1.DomainId,
                    //"Name": this.props.user.Part1.Name,
                    "AuthUsername": this.props.user.Part1.AuthUsername || '',
                    "hasPassword": this.props.user.Part1.HasPassword,
                    "Password": this.props.user.Part1.Password,
                    "Name": this.props.user.Part1.Name,
                    "PhoneNumber": this.props.user.Part1.PhoneNumber,
                    "ServiceNumberBehaviourTypeId": this.props.user.Part1.ServiceNumberBehaviourTypeId ? this.props.user.Part1.ServiceNumberBehaviourTypeId : null,
                    "AccountServiceTrunkServiceNumberId": this.props.user.Part1.AccountServiceTrunkServiceNumberId,
                };

                if (this.props.user.Part1.IsSynced) {
                    baseDefaults.Part1.SyncUserPart = this.props.user.Part1.Id;
                }

            }

            if (this.props.user.Part2) {
                baseDefaults['Part2'] = {
                    "Id": this.props.user.Part2.Id,
                    "AccountServiceId": this.props.user.Part2.AccountServiceId,
                    "Identifier": this.props.user.Part2.Identifier,
                    "DomainId": this.props.user.Part2.DomainId,
                    //"Name": this.props.user.Part2.Name,
                    "StateId": this.props.user.Part2.StateId,
                    "CountryId": this.props.user.Part2.CountryId,
                    "PhoneNumber": this.props.user.Part2.PhoneNumber,
                    "DefaultCallingPolicy": [],
                    "VMCallingPolicy": "",
                    "MOHCallingPolicy": "",
                    "PhoneNumberExtension": "",
                    "ShowExtension": 'Hide'
                }

                if (this.props.user.Part2.CallingPolicy) {
                    var temp = this.props.user.Part2.CallingPolicy.split('_');
                    var vm = temp[0];
                    var moh = temp[1];

                    baseDefaults['Part2']['VMCallingPolicy'] = vm;
                    baseDefaults['Part2']['MOHCallingPolicy'] = moh;

                    if (vm && moh) {
                        baseDefaults['Part2']['DefaultCallingPolicy'] = ['custom'];
                    }
                }

                if (this.props.user.Part2.IsSynced) {
                    baseDefaults.Part2.SyncUserPart = this.props.user.Part2.Id;
                    baseDefaults.Part2.IsExternallyManaged = this.props.user.Part2.IsExternallyManaged;
                }

                if (this.props.user.Part2.PhoneNumber && this.props.user.Part2.PhoneNumber.toLowerCase().includes("ext")) {
                    var phoneWithExt = this.props.user.Part2.PhoneNumber.toLowerCase().split(';ext=')
                    baseDefaults['Part2']['PhoneNumber'] = phoneWithExt[0];
                    baseDefaults['Part2']['PhoneNumberExtension'] = phoneWithExt[1];
                }

            }
        }

        if (baseDefaults.Part1.AccountServiceId === 0 && this.state.services.Part1.length > 0) {
            let service = this.state.services.Part1[0];
            baseDefaults.Part1.AccountServiceId = service.Id;
        }

        if (baseDefaults.Part2.AccountServiceId === 0 && this.state.services.Part2.length > 0) {
            let service = this.state.services.Part2[0];
            baseDefaults.Part2.AccountServiceId = service.Id;
        }

        return baseDefaults;
    }

    serviceDropdown(currentService, services, name, categoryName) {
        if (services && services.length === 1) {
            return (
                <>
                    <h4>{services[0].Name}</h4>
                    <Field type="hidden" name={name} readOnly={true} />
                </>
            );

        } else if (services && services.length > 1) {
            if (currentService && !currentService.SyncEnabled) {
                return (
                    <>
                        <h4>{currentService.Name}</h4>
                        <Field type="hidden" name={name} readOnly={true} />
                    </>
                );

            } else {
                var options = [];
                services.forEach(function (accService) {
                    options.push(
                        <option key={accService.Id} value={accService.Id}>{accService.Name}</option>
                    );
                });

                var readonly = (services.length === 1 ? true : false);
                return (
                    <>
                        <span>{categoryName}:</span> <Field className="form-control" component="select" name={name} readOnly={readonly}>
                            {options}
                        </Field>
                        <ErrorMessage name={name} />
                    </>
                );
            }
        }
    }

    toggleDeleteIsSubmitting = (cb = () => { }) => {
        this.setState({ deleteIsSubmitting: !this.state.deleteIsSubmitting }, cb);
    }

    deleteUser = (e) => {
        e.preventDefault();
        this.toggleDeleteIsSubmitting(this.props.deleteCallback);
    }

    formValidation = (values) => {

        let errors = {
            Part1: {},
            Part2: {},
            catchErr: null
        };

        try {
           
            // TODO: Fill in form errors here...
            const s1 = values.Part1.s1;
            if (s1) {
                if (s1.Variant && s1.Variant.ServiceCode !== 'pbx' && !s1.SyncEnabled) {
                    if (!values.Part1.hasOwnProperty('CountryId') || values.Part1.CountryId === "") {
                        errors['Part1']['CountryId'] = "Must select a country";
                    }
                }

                //Part1 is pbx / trunk.If the ServiceUserPart is synced in then instead of a form object
                //with all values we'll just be setting Part1.Id/Part1.SyncUserPart. Therefore we only
                //do form validation when we actually have direct user input here.
                if (!s1.SyncEnabled) {
                    if (!values.Part1.hasOwnProperty('Identifier') || values.Part1.Identifier === "") {
                        errors['Part1']['Identifier'] = "A value is required.";
                    } else if (/[@]/g.test(values.Part1.Identifier)) {
                        errors['Part1']['Identifier'] = "Cannot contain an @ symbol.";
                    }
                }
            }


            const s2 = values.Part2.s2;

            if (s2) {
                if (s2.Variant && (s2.Variant.ServiceCode === 'teams' || s2.Variant.ServiceCode === 'sfb') && !s2.SyncEnabled) {
                    if (!values.Part2.hasOwnProperty('CountryId') || values.Part2.CountryId === "") {
                        errors['Part2']['CountryId'] = "A country is required for this service.";
                    }
                }


                if (s2.Variant && s2.Variant.ServiceCode === 'teams') {
                    /**
                        * No sync verison first
                        */
                    if (!s2.SyncEnabled) {
                        if (!values.Part2.hasOwnProperty('Identifier') || values.Part2.Identifier === "") {
                            errors['Part2']['Identifier'] = "A telephone number is required";
                        } else if (!/^\+[0-9]+$/i.test(values.Part2.Identifier)) {
                            errors['Part2']['Identifier'] = "The telephone number entered is not valid";
                        } else if (!/^\+[0-9]{3,15}$/i.test(values.Part2.Identifier)) {
                            errors['Part2']['Identifier'] = "The telephone number must be between 3 and 15 digits";
                        }
                    }

                    /**
                        * Sync rules for Teams only carry about Phone Number
                        */
                    if (s2.SyncEnabled) {
                        /* We skip phone number check for trunks as we set the number ourselves are user creation */
                        if (s1 && s1.Variant) {
                            if (!(s1.Variant.Code === 'TRUNK' || s1.SyncEnabled)
                            ) {
                                if (!values.Part2.hasOwnProperty('PhoneNumber') || values.Part2.PhoneNumber === "") {
                                    errors['Part2']['PhoneNumber'] = "A telephone number must be provided.";
                                } else if (!/^\+[0-9]+$/i.test(values.Part2.PhoneNumber)) {
                                    errors['Part2']['PhoneNumber'] = "The telephone number provided is not valid.";
                                } else if (!/^\+[0-9]{3,15}$/i.test(values.Part2.PhoneNumber)) {
                                    errors['Part2']['PhoneNumber'] = "The telephone number must be between 3 and 15 digits";
                                }
                            }
                        }

                        if (!values.Part2.hasOwnProperty('SyncUserPart') || values.Part2.SyncUserPart === "") {
                            errors['Part2']['SyncUserPart'] = "A user must be selected";
                        }
                    }

                    if (values['Part2']['DefaultCallingPolicy'] && values['Part2']['DefaultCallingPolicy'].includes('custom')) {
                        if (!values['Part2']['VMCallingPolicy']) {
                            errors['Part2']['VMCallingPolicy'] = "You must select a value";
                        }
                        if (!values['Part2']['MOHCallingPolicy']) {
                            errors['Part2']['MOHCallingPolicy'] = "You must select a value";
                        }
                    }
          
                    if (values.Part2.PhoneNumberExtension && values.Part2.PhoneNumberExtension != "") {
                        if (!/^[0-9]*$/i.test(values.Part2.PhoneNumberExtension)) {
                            errors['Part2']['PhoneNumberExtension'] = "The extension number must only contain numbers";
                        }
                        else if (!/^[0-9]{0,8}$/i.test(values.Part2.PhoneNumberExtension)) {
                            errors['Part2']['PhoneNumberExtension'] = "The extension number must be between 1 and 8 digits";
                        }
                    } else if (!values.Part2.PhoneNumberExtension && values.Part2.ShowExtension.toLowerCase().includes('mandatory')) {
                        errors['Part2']['PhoneNumberExtension'] = "A extension number must be provided.";
                    }
                } else {
                    if (!values.Part2.hasOwnProperty('Identifier') || values.Part2.Identifier === "") {
                        errors['Part2']['Identifier'] = "A value is required.";
                    }
                }
            }

            if (_.isEmpty(errors['Part1'])) { delete (errors['Part1']); }
            if (_.isEmpty(errors['Part2'])) { delete (errors['Part2']); }
            if (_.isEmpty(errors['catchErr'])) { delete (errors['catchErr']); }

            console.log("Form Errors:", errors);
        } catch (e) {
            console.log('Service User Form, try/catch error has occured.', e);
            errors.catchErr = 'An unexpected error has occured.';
        }


        return errors;
    }

    render() {
        var self = this;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={this.formValidation}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;

                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);

                    if (values['StateId']) {
                        values['CountryId'] = '';
                    }
                    if (values.Part1['StateId']) {
                        values.Part1['CountryId'] = '';
                    }
                    if (values.Part2['StateId']) {
                        values.Part2['CountryId'] = '';
                    }

                    // To be double safe, we delete any PhoneNumber set when we are not syncing
                    var s2 = values.Part2.s2;

                    // Do some work on these values...
                    if (!values.Part1.CountryId || values.Part1.CountryId === "") {
                        delete values.Part1.CountryId;
                    } else {
                        values.Part1.CountryId = parseInt(values.Part1.CountryId, 10);
                    }

                    if (!values.Part2.CountryId || values.Part2.CountryId === "") {
                        delete values.Part2.CountryId;
                    } else {
                        values.Part2.CountryId = parseInt(values.Part2.CountryId, 10);
                    }

                    // Both DomainId and AuthUsername make no sense as blank strings
                    ['Part1', 'Part2'].forEach(function (part) {
                        if (values[part].DomainId === "") {
                            values[part].DomainId = null;
                        }
                        if (values[part].AuthUsername === "") {
                            values[part].AuthUsername = null;
                        }
                    });

                    // If p1 is trunk, and sync on s2, then set phone number from one side to the other...
                    var s1 = values.Part1.s1
                    if (s1 && s1.Variant.Code === 'TRUNK' && s2.SyncEnabled) {
                        values['Part2']['PhoneNumber'] = values['Part1']['Identifier'];
                    }
                    // If p1 is pbx and sync enabled, set PhoneNumber from pbx 'PhoneNumber' field.
                    if (s1 && s1.SyncEnabled && s2.SyncEnabled) {
                        values['Part2']['PhoneNumber'] = values['Part1']['PhoneNumber'];
                    }
                   
                    if (values.Part2['PhoneNumberExtension'] && values.Part2['PhoneNumberExtension'] != "") {
                        values['Part2']['PhoneNumber'] += `;ext=${values.Part2['PhoneNumberExtension']}`
                    }

                    if (values['Part2']['ShowExtension']) {
                        delete values['Part2']['ShowExtension'];
                    }
                    if (values['Part2']['PhoneNumberExtension']) {
                        delete values['Part2']['PhoneNumberExtension'];
                    }

                    // If p1 is trunk, only allow set of ServiceNumberBehaviourTypeId & AccountServiceTrunkServiceNumberId
                    if (s1 && s1.Variant.Code === 'TRUNK') {
                        values['Part1'] = {
                            'Id': values['Part1']['Id'],
                            'AccountServiceId': values['Part1']['AccountServiceId'],
                            'ServiceNumberBehaviourTypeId': values['Part1']['ServiceNumberBehaviourTypeId'],
                            'AccountServiceTrunkServiceNumberId': values['Part1']['AccountServiceTrunkServiceNumberId']
                        };
                    }

                    // If we have calling policy set on the second part, combine to the one field
                    if (values['Part2']['DefaultCallingPolicy']
                        && values['Part2']['DefaultCallingPolicy'].includes('custom')
                        && values['Part2']['MOHCallingPolicy']
                        && values['Part2']['VMCallingPolicy']
                    ) {
                        values['Part2']['CallingPolicy'] = values['Part2']['VMCallingPolicy'].concat(
                            '_', values['Part2']['MOHCallingPolicy']
                        );
                        delete values['Part2']['VMVMCallingPolicy'];
                        delete values['Part2']['DefaultCallingPolicy'];
                        delete values['Part2']['MOHCallingPolicy'];
                    }

                    self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => {

                    //if service ids have been passed to serviceuserform, use them to fetch the full version of the services

                    var services = (
                        values.Part1.AccountServiceId && values.Part1.AccountServiceId != 0
                        && values.Part2.AccountServiceId && values.Part2.AccountServiceId != 0
                    )
                        ? ServicesQuery(self.props.account.Id, [values.Part1.AccountServiceId, values.Part2.AccountServiceId])
                        : null;
  
                    var isServicesLoading = services && services === "Loading...";
                    var isServicesError = services && services.includes("An error has occurred");
  


                    /**
                     * As a default, use the short-form version of the services for the s1 and s2 values.
                     * Then, when the full services have finished loading, reassign these values with the full
                     * version of the service
                     **/

                    var s1 = null;
                    var s2 = null;
                    var s1Template = null;
                    var s2Template = null;

                    if (services && !isServicesLoading && !isServicesError && services.length > 1) {
                        // If the full services have successfully loaded then assign them
                        s1 = _.find(services, function (s) { return s.service.Id === values.Part1.AccountServiceId }).service;
                        s2 = _.find(services, function (s) { return s.service.Id === values.Part2.AccountServiceId }).service;
                        s1Template = _.find(services, function (s) { return s.service.Id === values.Part1.AccountServiceId }).template;
                        s2Template = _.find(services, function (s) { return s.service.Id === values.Part2.AccountServiceId }).template;
                        if (!values.Part1.s1 && s1 || values.Part1.s1 && s1 && values.Part1.s1 != s1) {
                            setFieldValue('Part1.s1', s1);
                        }
                        if (!values.Part2.s2 && s2 || values.Part2.s2 && s2 && values.Part2.s2 != s2) {
                            setFieldValue('Part2.s2', s2);
                        }
                        if (s1Template && s1Template.Visibility && s1Template.Visibility.ShowExtension && values.Part2.ShowExtension != s1Template.Visibility.ShowExtension) {
                            setFieldValue('Part2.ShowExtension', s1Template.Visibility.ShowExtension);
                        }

                    } else {
                        // If we only have summary services, let's move data
                        // points we _do_ know about to be in correct place
                        var s1Temp = _.find(self.props.services.rawList, function (s) { return s.Id === values.Part1.AccountServiceId });
                        var s2Temp = _.find(self.props.services.rawList, function (s) { return s.Id === values.Part2.AccountServiceId });

                        s1 = s1Temp ? self.convertToFauxService(s1Temp) : null;
                        s2 = s2Temp ? self.convertToFauxService(s2Temp) : null;
                    }

                    var dropdown1 = self.serviceDropdown(
                        self.props.user && self.props.user.Part1 && self.props.user['Part1']['AccountServiceId'] ? s1 : null,
                        self.state.services.Part1,
                        'Part1.AccountServiceId',
                        getPbxLabel(self.props.account)
                    );

                    var dropdown2 = self.serviceDropdown(
                        self.props.user && self.props.user.Part2 && self.props.user['Part2']['AccountServiceId'] ? s2 : null,
                        self.state.services.Part2,
                        'Part2.AccountServiceId',
                        'Service'
                    );

                    ['Part1', 'Part2'].forEach(function (part) {
                        let service = part === 'Part1' && s1 ? s1 : (part === 'Part2' && s2 ? s2 : null);
                        if (service && service.Domains && service.Domains.length) {
                            values[part].DomainId = service.Domains[0].Id;
                        } else {
                            values[part].DomainId = "";
                        }
                    });

                    var userFormAlertMessage = null;
                    var readonlyP1 = false;
                    if (isServicesLoading) {
                        readonlyP1 = true;
                        userFormAlertMessage = (
                            <div className="alert alert-full-form">
                                <p>
                                    <i className="fa fa-spin fa-solid fa-spinner"></i>
                                    &nbsp;
                                    Loading Services...
                                </p>
                            </div>
                        );
                    } else if (this.props.user && this.props.user.Part1 && !this.props.user.Part2) {
                        readonlyP1 = true;
                        userFormAlertMessage = (
                            <div className="col-md-12">
                                <div className="alert alert-warning">
                                    <p>This user has a service part that is not attached to a Microsoft user. Complete the form below to activate the service for this user.</p>
                                </div>
                            </div>
                        );
                    }


                    var userForm1 = null;
                    if (s1) {
                        userForm1 = (
                            <ServiceUserPartForm
                                countries={self.props.countries}
                                service={s1}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                readonly={readonlyP1}
                                namespace="Part1" />
                        );
                    }

                    var userForm2 = null;
                    if (s2) {
                        let numberFromOtherPart = false;
                        if (s1 && s1.Variant) {
                            if (s1.Variant.Code === 'TRUNK' || s1.Variant.Code === 'PBX' && s1.SyncEnabled) {
                                numberFromOtherPart = true;
                            }
                        } else if (s1 && s1.ServiceVariantCode && (s1.ServiceVariantCode === 'TRUNK' || s1.ServiceVariantCode === 'PBX' && s1.SyncEnabled)) {
                            numberFromOtherPart = true;
                        }

                        let serviceNameFromOtherPart = ((s1 && s1.Variant && s1.Variant.Code === 'TRUNK') || (s1 && s1.ServiceVariantCode && s1.ServiceVariantCode === 'TRUNK') ? 'Trunk' : 'PBX');

                        if (s1Template)
                        {
                            let showExtension = 'Hide';
                            if (s1Template.Visibility
                                && s1Template.Visibility.hasOwnProperty('ShowExtension')) {
                                showExtension = s1Template.Visibility.ShowExtension;
                            }

                            userForm2 = ( <ServiceUserPartForm
                                countries={self.props.countries}
                                service={s2}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                numberFromOtherPart={numberFromOtherPart}
                                serviceNameFromOtherPart={serviceNameFromOtherPart}
                                showExtensionVisibility={showExtension}
                                readonly={false}
                                namespace="Part2" />)
 
                        }
                    }

                    if (!this.props.showParts && (!s1 || !s2) && !isServicesError && !isServicesLoading) {
                        return (
                            <>
                                <p>Unable to find suitable services. Please check you have a PBX service and Microsoft service configured.</p>
                            </>
                        );
                    }

                    var disableUserButton = null;

                    if (this.props.showDisable) {
                        var text = 'Disable User';
                        if (this.props.user.LicenceState !== 'Licenced') {
                            text = 'Enable User';
                        }
                        disableUserButton = (
                            <>
                                <span>&nbsp;</span>
                                <button className="btn mb-2 delete-user-trigger" onClick={this.props.disableToggleCallback}>
                                    {text}
                                </button>
                            </>
                        );
                    }

                    let isFormDisabled = isSubmitting || isServicesLoading;
                    let formTitle = null;
                    if (self.props.user && self.props.user.hasOwnProperty('AllowEditInUI')) {
                        if (self.props.user.AllowEditInUI == false) {
                            isFormDisabled = true;
                            formTitle = 'UI edits have been disabled for this user';
                        }
                    }

                    return (
                        <div>
                            <form className="form user-form update-domain-form" onSubmit={handleSubmit}>

                                {errors.catchErr &&
                                    <div className='alert alert-danger'>
                                     {errors.catchErr}
                                </div>
                                }

                                <fieldset disabled={isFormDisabled} title={formTitle}>
                                    <div className="row">
                                        {userFormAlertMessage}

                                        {userForm2 && dropdown2 &&
                                            <div className="col-md-12">
                                                <div className="service-line">{dropdown2}</div>
                                                {userForm2}
                                            </div>
                                        }
                                        
                                        {userForm1 && dropdown1 &&
                                            <div className="col-md-12">
                                                <div className="service-line">{dropdown1}</div>
                                                {userForm1}
                                            </div>
                                        }
                                        
                                    </div>

                                    <hr />

                                    <div className="user-form-action-buttons">
                                        {this.props.showDelete !== undefined ? (
                                            <DefaultButton
                                                text={<><i className="fa-solid fa-trash"></i></>} onClick={self.showModal} disabled={isFormDisabled}
                                            />
                                        ) : null}

                                        <Dialog
                                            hidden={self.state.hideDialog}
                                            onDismiss={self.hideModal}
                                            dialogContentProps={{
                                                type: DialogType.normal,
                                                title: 'Delete User',
                                                subText: 'This user will be permanently deleted. Are you sure?'
                                            }}
                                            modalProps={{
                                                styles: { main: { maxWidth: 450 } },
                                            }}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={self.deleteUser} disabled={self.state.deleteIsSubmitting} text="Delete User" />
                                                <DefaultButton onClick={self.hideModal} text="Cancel" />
                                            </DialogFooter>
                                        </Dialog>

                                        {disableUserButton}
                                        <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}> {self.props.submitButtonText} </SubmitButton>
                                        {!self.props.hideCloseButton && (
                                            <button type='button' className="btn right-action-button mb-2 close-form" onClick={self.props.closeForm}>{this.closeButtonText()}</button>
                                        )}
                                    </div>

                                    {/* <!-- required due to floating-right action buttons --> */}
                                    <div className="clearfix"></div>
                                </fieldset>
                            </form>
                        </div>
                    )
                }
                }

            </Formik>
        );
    }

    closeButtonText() {
        if (this.props.closeButtonText) {
            return this.props.closeButtonText;
        } else {
            return 'Cancel';
        }
    }

}
export default connect(state => ({
    account: state.account.account,
    services: state.services
}))(ServiceUserForm);