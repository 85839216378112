import React, { Component } from 'react';
import Select from 'react-select'
import {
    cmsAddService, cmsGetPBXTemplate, cmsGetPBXTemplates,
    cmsServiceEnableSync,
    getErrMsg
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import PBXServiceForm from './PBXServiceForm';
import TrunkServiceForm from './TrunkServiceForm';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';


class PBXTrunkServiceNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pbxTemplates: [],
            pbxTemplatesLoaded: false,
            pbxCustomTemplate: null,
            selectedTemplateId: null,
            selectedTemplateFull: null,
            showPbxToggle: (props.existingCount > 0 ? true : false)
        };

        // If toggle is shown, default to _not_ showing the form
        this.state['showNewPbxForm'] = (!this.state.showPbxToggle ? true : false);

        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.toggleClick = this.toggleClick.bind(this);
        this.resetAndClose = this.resetAndClose.bind(this);
        this.selectCustom = this.selectCustom.bind(this);
        this.newPbxCompleteWrapper = this.newPbxCompleteWrapper.bind(this);
        this.savePBX = this.savePBX.bind(this);
    }

   componentDidMount() {
        this.getPBXTemplates();
    }

    getPBXTemplates() {
        var self = this;
        cmsGetPBXTemplates(
            self.props.account.Id,
            self.props.variant.Id,
            function (callmsData) {
                var pbxCustom = null;
                callmsData.Results.forEach(function (t) {
                    if (t.Name === 'Custom') {
                        pbxCustom = t;
                    }
                });

                self.setState({
                    pbxTemplates: callmsData.Results,
                    pbxTemplatesLoaded: true,
                    pbxCustomTemplate: pbxCustom,
                }, function () {
                    if (self.state.pbxTemplates.length === 1) {
                        self.handleTemplateChange(self.state.pbxTemplates[0]);
                    }
                });
        },
            function (error) {
                toast.error(error);
            }
        );
    }

    handleTemplateChange(selectedOption) {
        var self = this;
        cmsGetPBXTemplate(
            this.props.account.Id,
            selectedOption.Id,
            function (data) {
                self.setState({
                    selectedTemplateId: selectedOption,
                    selectedTemplateFull: data
                });
            },
            function (err) {
                toast.error("Unable to retrieve full template: " + selectedOption.Name + ". " + getErrMsg(err));
            }
        );
    };

    defaultServiceVariant(Variants) {
        var lastAvail;
        var availCount = 0;
        Variants.forEach(function (v) {
            if (v.IsAvailable) {
                lastAvail = v;
                availCount++;
            }
        });
        if (availCount === 1) {
            return lastAvail.Id;
        } else {
            return '';
        }
    }

    savePBX(values, okCb, errorCb) {
        var self = this;
        let accId = values['accountId'];
        cmsAddService(
            accId,
            values,
            function (data) {
                toast.success("Service successfully enabled");

                self.props.saveServiceCallback && self.props.saveServiceCallback();

                if (values['ServiceSyncModuleId'] && values['SyncEnabled']) {
                    cmsServiceEnableSync(
                        accId,
                        data.Id,
                        {},
                        function (ok) {
                            toast.success("Sync is now enabled");
                            okCb && okCb(true, data, true);
                        },
                        function (err) {
                            toast.error(getErrMsg(err));
                            okCb && okCb(true, data, false);
                        }
                    );
                } else {
                    okCb && okCb(true, data, false);
                }
            },
            errorCb
        );
    }

    toggleClick(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState(prevState => ({
            showNewPbxForm: !prevState.showNewPbxForm
        }));
    }

    resetAndClose(e) {
        var self = this;
        this.setState({
            selectedTemplateFull: null,
            selectedTemplateId: null
        }, function () {
            self.toggleClick(e)
        });
    }

    selectCustom(e) {
        this.handleTemplateChange(this.state.pbxCustomTemplate);
    }

    newPbxCompleteWrapper() {

        this.props.refetchServices();

        if (this.state.pbxTemplates.length === 1) {
            this.toggleClick();
        } else {
            this.resetAndClose();
        }
    }

    render() {
        var self = this;
        var canCloseForm = true;

        if (!self.state.pbxTemplatesLoaded) {
            return (<p>Loading Templates...</p>);
        }

        if (self.state.pbxTemplatesLoaded && self.state.pbxTemplates.length === 0) {
            console.log("It is not possible to add additional service entries here as this account does not have access to any templates.");
            return <div className="alert alert-warning"><p>There are no templates available for this service on your account.</p></div>;
        }

        var pbxTemplatesWrapper = null;
        if (self.state.pbxTemplates.length > 1) {
            pbxTemplatesWrapper = (
                <>
                    <p>Start by selecting your {self.props.serviceName} from the available templates:</p>
                    <Select
                        options={self.state.pbxTemplates}
                        value={self.state.selectedTemplateId}
                        onChange={this.handleTemplateChange}
                        className="select-dropdown select-dropdown--pbx"
                        getOptionLabel={(option) => option.Name}
                        getOptionValue={(option) => option.Id}
                    />
                </>
            );
        }

        if (self.state.pbxTemplates.length <= 1 && this.props.existingCount === 0) {
            canCloseForm = false;
        }

        var pbxForm = null;
        var divider = null;
        if (self.state.selectedTemplateFull && pbxTemplatesWrapper) {
            divider = <hr />;
        }
        if (self.state.selectedTemplateFull) {
            if (self.props.serviceName.toLowerCase() === 'trunk') {

                pbxForm = (
                    <TrunkServiceForm
                        pbxTemplate={self.state.selectedTemplateFull}
                        saveCallback={self.savePBX}
                        saveText={"Add " + self.props.serviceName}
                        closeText="Cancel"
                        closeCallback={self.resetAndClose}
                        availableService={self.props.availableService}
                        updateServicesTrigger={self.newPbxCompleteWrapper}
                        updateFullServiceTrigger={self.updateFullServiceTrigger}
                        variant={self.props.variant}
                        serviceName={self.props.serviceName}
                        existingCount={self.props.existingCount}
                        restrictServiceTabs={self.props.restrictServiceTabs}
                    />
                );

            } else {
                pbxForm = (
                    <PBXServiceForm
                        pbxTemplate={self.state.selectedTemplateFull}
                        saveCallback={self.savePBX}
                        saveText={"Add " + self.props.serviceName}
                        closeText="Cancel"
                        closeCallback={canCloseForm ? self.resetAndClose : null}
                        availableService={self.props.availableService}
                        variant={self.props.variant}
                        updateServicesTrigger={self.newPbxCompleteWrapper}
                        serviceName={self.props.serviceName}
                        existingCount={self.props.existingCount}
                        restrictServiceTabs={self.props.restrictServiceTabs}
                        skipTeamsTrueTransferCheck={self.props.skipTeamsTrueTransferCheck}
                        skipAutoSync={self.props.skipAutoSync}
                        skipSyncCallback={self.props.skipSyncCallback}
                    />
                );
            }
        }

        var pbxFormWrapper = null;
        if (this.state.showNewPbxForm || this.props.existingCount === 0) {
            var pbxNotListed = null;
            if (this.state.pbxCustomTemplate && this.state.pbxTemplates.length > 1) {
                pbxNotListed = <p className="text-center">
                    <button className="btn btn--pbx-custom-shortcut btn-xs" onClick={self.selectCustom}>My {self.props.serviceName} is not listed</button>
                    </p>
            }

            pbxFormWrapper = (
                <>
                    <div className="alert alert-info">
                        <div className="row">
                            <div className="col-md-offset-3 col-md-6">
                                {pbxTemplatesWrapper}
                                {pbxNotListed}
                            </div>
                        </div>
                        {divider}
                        {pbxForm}
                    </div>
                </>
            );
        }

        // Show toggle only when > 0 pbx
        // If show value is true, show form.
        var pbxToggle = null;
        //if (this.state.showPbxToggle) {
        if (this.props.existingCount > 0 && this.props.limitToOneService) {
            pbxToggle = (
                <button className="btn btn-xs btn-link" onClick={() => {
                    this.props.history.push({
                        pathname: `/portal/${this.props.account.Id}/services`,
                    })
                }}>
                    <i className="fa-solid fa-plus"></i> Add Additional {self.props.serviceName} through the services tab
                </button>
            );
        } else if (this.props.existingCount > 0 && !this.props.limitToOneService) {
            pbxToggle = (
                <button className="btn btn-xs btn-link" onClick={self.toggleClick}>
                    <i className="fa-solid fa-plus"></i> Add Additional {self.props.serviceName}
                </button>
            );
        }

        return (
            <>
                {pbxToggle}
                {pbxFormWrapper}
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PBXTrunkServiceNew);
