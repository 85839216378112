import { Formik } from 'formik';
import { Toggle } from 'office-ui-fabric-react';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { canAccess, isSystemOwner } from '../../CallMSUIHelpers.js';
import CountryIdAsyncSelect from '../../CountryIdAsyncSelect.js';
import { SubmitButton } from '../../FormHelpers';
import FieldWithDisposition from '../../Services/FieldWithDisposition';
import { allCodecs, AllowedIPs, CountryHOC, E164Form } from '../../Services/ServiceFormHelpers';
import { ValidateProxy, ValidateRealm } from '../../Services/ServiceValidationHelpers.js';
import { getPostTemplateData } from './BrandingTemplateAssembler.js';
import {
    getCallPolicyFormObject,
    getCallPolicyVMFormObject,
    getCallPolicyMOHFormObject
} from '../../CallingPolicyHelpers';
import EmergencyDropDown from '../../components/service/EmergencyDropDown.js';
import {
    getSBCModeOptions,
    getTransferModeOptions,
    getPAIHeaderTypeTrunkOptions,
    getFromHeaderTypeTrunkOptions
} from '../../Services/ServiceFormHelpers.js'

var _ = require('lodash');
var checkIp = require('check-ip');

const ShowOptions = [
    { label: 'Edit Mandatory', value: 'EditMandatory' },
    { label: 'Edit Optional', value: 'EditOptional' },
    { label: 'Locked Edit Mandatory', value: 'LockedEditMandatory' },
    { label: 'Locked Edit Optional', value: 'LockedEditOptional' },
    { label: 'View Only', value: 'View' },
    { label: 'Hide', value: 'Hide' }];

const ProtocolOptions = [
    { label: 'No default selected...', value: '' },
    { label: 'UDP', value: 'udp' },
    { label: 'TCP', value: 'tcp' },
    { label: 'TLS', value: 'tls' }];

const YesNoOptions = [
    { label: 'No default selected...', value: '' },
    { label: 'Yes', value: true },
    { label: 'No', value: false }];

const E164ModeOptions = [
    { label: 'Select...', value: '' },
    { label: 'E164 with +', value: 'E164WithPlus' },
    { label: 'E164 without +', value: 'E164WithoutPlus' },
    { label: 'Localized', value: 'Localized' }];

const CertificationLevelOptions = [
    { label: 'No default selected...', value: '' },
    { label: 'Community', value: 'Community' },
    { label: 'Native', value: 'Native' },
    { label: 'Vendor', value: 'Vendor' }];

const AllowReadSBCAllocationOptions = [
    { label: 'No default selected...', value: '' },
    { label: 'True', value: true },
    { label: 'False', value: false }];

class BrandingTemplateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialingPrefixHelp: false,
            pbxTemplateHistory: this.props.pbxTemplateHistory
        };

        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
    }

    toggleHelp = (name) => {
        this.setState({ [name]: !this.state[name]});
    }

    dismissHelp = name => {
        this.setState({ [name]: false });
    }

    getInitialOption = (value, options) => {
        return value === ''
            ? null
            : options.find(x => x.value === value);
    }

    getInitialOptions = (values, options) => {
        var results = [];

        if (values !== null && values.length !== 0) {
            _.forEach(values, function (value) {
                var result = value === ''
                    ? null
                    : options.filter(x => x.value === value);

                if (result !== null) {
                    results.push(result[0]);
                }
            });
        }

        return results;
    }

    getInitialValues = (values) => {
        values.Visibility.ShowLocation = this.getInitialOption(
            values.Visibility.ShowLocation,
            ShowOptions);

        values.Visibility.ShowProxy = this.getInitialOption(
            values.Visibility.ShowProxy,
            ShowOptions);

        values.Visibility.ShowRealm = this.getInitialOption(
            values.Visibility.ShowRealm,
            ShowOptions);

        values.Visibility.ShowSBCMode = this.getInitialOption(
            values.Visibility.ShowSBCMode,
            ShowOptions);

        values.Visibility.ShowAllowedIPs = this.getInitialOption(
            values.Visibility.ShowAllowedIPs,
            ShowOptions);

        values.Visibility.ShowServiceSyncModule = this.getInitialOption(
            values.Visibility.ShowServiceSyncModule,
            ShowOptions);

        values.Visibility.ShowExpiry = this.getInitialOption(
            values.Visibility.ShowExpiry,
            ShowOptions);

        values.Visibility.ShowProtocol = this.getInitialOption(
            values.Visibility.ShowProtocol,
            ShowOptions);

        values.Visibility.ShowTransferMode = this.getInitialOption(
            values.Visibility.ShowTransferMode,
            ShowOptions);

        values.Visibility.ShowSuppressContactDataParam = this.getInitialOption(
            values.Visibility.ShowSuppressContactDataParam,
            ShowOptions);

        values.Visibility.ShowEncryptMedia = this.getInitialOption(
            values.Visibility.ShowEncryptMedia,
            ShowOptions);

        values.AllowedCodecs = this.getInitialOptions(
            values.AllowedCodecs,
            allCodecs());

        values.Visibility.ShowAllowedCodecs = this.getInitialOption(
            values.Visibility.ShowAllowedCodecs,
            ShowOptions);

        values.Visibility.ShowFromHeaderType = this.getInitialOption(
            values.Visibility.ShowFromHeaderType,
            ShowOptions);

        values.Visibility.ShowPAIHeaderType = this.getInitialOption(
            values.Visibility.ShowPAIHeaderType,
            ShowOptions);

        values.Visibility.ShowDialingPrefixes = this.getInitialOption(
            values.Visibility.ShowDialingPrefixes,
            ShowOptions);

        values.Visibility.ShowAuthUsername = this.getInitialOption(
            values.Visibility.ShowAuthUsername,
            ShowOptions);

        values.Visibility.ShowCredentials = this.getInitialOption(
            values.Visibility.ShowCredentials,
            ShowOptions);

        values.Visibility.ShowCallingPolicy = this.getInitialOption(
            values.Visibility.ShowCallingPolicy,
            ShowOptions);

        values.Visibility.ShowEmergencyTrunks = this.hasEmergencyTrunkFeature() || this.hasBrandContributorRole() || this.hasSystemOwnerRole();

        values.Visibility.ShowExtension = this.getInitialOption(
            values.Visibility.ShowExtension,
            ShowOptions);
        
        values.Visibility.ShowApplyPAIDPrefix = this.getInitialOption(
            values.Visibility.ShowApplyPAIDPrefix,
            ShowOptions);

        if (values.ApplyPAIDPrefix == true || (values.ApplyPAIDPrefix.length && values.ApplyPAIDPrefix.includes("checked"))) {
            values.ApplyPAIDPrefix = ["", "checked"];
        } else {
            values.ApplyPAIDPrefix = [""];
        }

        return values;
    }

    hasEmergencyTrunkFeature()
    {
        return (
            this.props.initialValues.ServiceVariantCode == "PBX" &&
            this.props.account.Features &&
            this.props.account.Features.includes("EMERGENCYTRUNK")
      );
    }

    hasBrandContributorRole()
    {
        return (
        this.props.baseAccount.roles &&
            this.props.baseAccount.roles.filter(x => x.Name === "System Owner" || x.Name === "Brand Contributor").length > 0
      );
    }

    hasSystemOwnerRole()
    {
        return (
        this.props.baseAccount.roles &&
            this.props.baseAccount.roles.filter(x => x.Name === "System Owner").length > 0
      );
    }

    getControlGroup = (leftControl, rightControl) => {
        let left = {
            width: '60%'
        };

        let right = {
            width: '40%',

        };

        let wrapper = {
            width: '100%',
            display: 'inline-flex',
            alignItems: "flex-end"
        };

        return (
            <div style={wrapper}>
                <div style={left}>
                    {leftControl}
                </div>
                <div style={right}>
                    {rightControl}
                </div>
            </div>
        );
    }

    getSelect = (name, value, setFieldValue, options) => {
        return (
            <Select
                value={value}
                getOptionLabel={options => options.label}
                getOptionValue={options => options.value}
                options={options}
                className={"select-dropdown disposition-selector"}
                name={name}
                onChange={(val) => {
                    setFieldValue(name, val)
                }}
                searchable={true}
            />
        )
    }

    getE164Inputs = () => {
        var fields = [{
            Id: 'FromPBXInternationalPrefix',
            Label: 'Inbound International Prefix',
            Group: 'e164from',
            VisibilityKey: 'DialingPrefixes',
            Type: 'text'
        },
        {
            Id: 'FromPBXNationalPrefix',
            Label: 'Inbound National Prefix',
            VisibilityKey: 'DialingPrefixes',
            Group: 'e164from',
            Type: 'text'
        },
        {
            Id: 'ToPBXInternationalPrefix',
            Label: 'Outbound International Prefix',
            VisibilityKey: 'DialingPrefixes',
            Group: 'e164to',
            Type: 'text'
        },
        {
            Id: 'ToPBXNationalPrefix',
            Label: 'Outbound National Prefix',
            VisibilityKey: 'DialingPrefixes',
            Group: 'e164to',
            Type: 'text'
        }]

        fields.forEach(function (obj) {
            if (!obj.State) {
                obj.State = 'EditOptional';
            }
        })

        return fields;

    }

    getE164Render = (values, errors, touched, setFieldValue, inputs, isTrunk) => {
        return (
            <CountryHOC
                CountryId={values.CountryId}
                StateId={values.StateId}
                accountId={this.props.account.Id}
            >
                <E164Form
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    AllInputs={inputs}
                    isTrunk={isTrunk}
                    account={this.props.account}
                />
            </CountryHOC>
        );
    }

    goToTemplates = (accountId) => {
        this.props.history.push(`/accounts/${accountId}/branding`);
    }

    onDeleteTemplate = (templateInUse) => {
        var self = this;

        if (templateInUse) {
            toast.error("Template in use, delete unavailable.");
        } else {
            self.props.deleteAction(
                function (ok) {
                    toast.success("Template successfully deleted.");
                    self.goToTemplates(self.props.account.Id);
                }, function (error) {
                    toast.error(error);
                }
            )
        }
    };

    renderDedicatedIPTag = (
        formik = { values: { DedicatedIPTag: '' }, touched: {}, errors: [], setFieldValue: () => { } }
    ) => {
        if (canAccess('Branding', this.props.baseAccount.roles)) {
            return (
                <div className="form-group">
                    <div className="alert system-owner-container">
                        <FieldWithDisposition
                            visibility={'EditOptional'}
                            formObject={{
                                Type: 'text',
                                Id: 'DedicatedIPTag',
                                Label: (
                                    <div>
                                        <i
                                            className="fa-solid fa-user-secret"
                                            title="System Owner Only"
                                        />
                                        Dedicated IP Tag
                                        <small className='fw-normal'> (System Owner Only)</small>
                                    </div>),
                                Values: formik.values.DedicatedIPTag,
                                Placeholder: 'Select...',
                            }}
                            formValues={formik.values}
                            touched={formik.touched}
                            errors={formik.errors}
                            setFieldValue={formik.setFieldValue} />
                    </div>
                </div>
            );
        }

        if (formik.values.DedicatedIPTag != null && formik.values.DedicatedIPTag.length > 0) {
            return (
                <div
                    className='alert alert-success fw-bolder'
                >
                    This template is set to use a Dedicated IP
                </div>
            );
        }

        return null;
    }

    render = () => {
        var self = this;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={self.getInitialValues(self.props.initialValues)}
                validate={values => {
                    let errors = {};

                    if (!values.TemplateName) {
                        errors.TemplateName = 'You must enter a name for this template.';
                    }

                    if (!values.E164Mode) {
                        errors.E164Mode = 'You must select a value.';
                    }

                    if (!values.SBCMode) {
                        errors.SBCMode = 'You must select a value.';
                    }

                    if (values.Realm) {
                        var error = ValidateRealm(values.Realm, 'SIP Domain');
                        if (error) {
                            errors.Realm = error;
                        }
                    }

                    if (values.Proxy) {
                        var error = ValidateProxy(values.Proxy, 'SIP Proxy');
                        if (error) {
                            errors.Proxy = error;
                        }
                    }

                    if (values.SBCMode === 'SingleNonRegistration'
                        || values.SBCMode === 'RedundantNonRegistration'
                    ) {
                        var ipError = [];

                        if (values.AllowedIPs.length === 0 || values.AllowedIPs[0] === "") {
                            ipError[0] = "You must enter at least one IP.";
                        }

                        if (values.AllowedIPs > 1
                            || (values.AllowedIPs.length === 1 && values.AllowedIPs[0] !== "")
                        ) {
                            _.forEach(values.AllowedIPs, function (ip, i) {
                                var result = checkIp(ip);
                                if (!result.isValid) {
                                    ipError[i] = "You must enter a valid IP address.";
                                } else if (!result.isPublicIp) {
                                    ipError[i] = "You must enter a public IP address.";
                                }
                            });
                        }

                        if (ipError.length) {
                            errors.AllowedIPs = ipError;
                        }
                    }

                    if (values.DedicatedIPTag != null && values.DedicatedIPTag.length > 50) {
                        errors.DedicatedIPTag = 'Must be 50 characters limit.';
                    }

                    return errors;
                }}

                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var values = _.cloneDeep(originalValues);
                    values['CallingPolicy'] = null;
                    if (values['DefaultCallingPolicy'] && values['DefaultCallingPolicy'].includes('custom')) {
                        var vm = values['VMCallingPolicy'];
                        var moh = values['MOHCallingPolicy'];
                        if (vm && moh) {
                            values['CallingPolicy'] = vm.concat('_', moh);
                        }
                    } else {
                        values['CallingPolicy'] = 'VM_MOHTEAMS';
                    }

                    if (values['ApplyPAIDPrefix'] && values['ApplyPAIDPrefix'].includes('checked')) {
                        values['ApplyPAIDPrefix'] = true;
                    } else {
                        values['ApplyPAIDPrefix'] = false;
                    }

                    // Tidy up unused temp/wrapper values to clean API request body
                    delete values['VMCallingPolicy'];
                    delete values['MOHCallingPolicy'];
                    delete values['DefaultCallingPolicy'];
                    delete values['OriginalCallingPolicy'];
                    this.props.submitAction(
                        getPostTemplateData(values),
                        function (ok) {
                            setSubmitting(false);
                            toast.success("Template successfully saved.");
                            self.goToTemplates(self.props.account.Id);
                        }, function (error) {
                            setSubmitting(false);
                            toast.error(error);
                        }
                    );
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => {

                    var isTrunk = values.ServiceVariantCode === "TRUNK";
                    let SBCMode = getSBCModeOptions(isTrunk, values);
                    let SBCModeOptions = SBCMode.options;

                    return (
                        <>
                            <div>
                                <form className="form form-templates" onSubmit={handleSubmit}>
                                    <fieldset disabled={isSubmitting}>
                                        <div className="user-form-parts-wrapper" style={{ marginBottom: "24px" }}>
                                            <div className="form-group">
                                                <label>Is Available</label>
                                                <Toggle name="IsEnabled"
                                                    value={values.IsEnabled}
                                                    checked={values.IsEnabled}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            e.currentTarget.name,
                                                            !e.currentTarget.checked)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Template Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" name="TemplateName" value={values.TemplateName} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.TemplateName && touched.TemplateName &&
                                                    <span className="error-message">{errors.TemplateName}</span>}
                                            </div>
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'TemplateVersion',
                                                            Label: 'Template Version',
                                                            Values: this.props.pbxTemplateHistory,
                                                            Placeholder: 'Select...',
                                                            onChangeHook: function (name, newVal, values, setFieldValue) {
                                                                self.props.selectPBXTemplate(newVal);
                                                            }
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <CountryIdAsyncSelect
                                                            countryValue={values.CountryId}
                                                            countryName={"CountryId"}
                                                            stateValue={values.StateId}
                                                            stateName={"StateId"}
                                                            onChange={setFieldValue}
                                                            defaultOptions={[{
                                                                Id: "",
                                                                Name: 'No Default Country',
                                                            }]}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        {self.getSelect(
                                                            "Visibility.ShowLocation",
                                                            values.Visibility.ShowLocation,
                                                            setFieldValue,
                                                            ShowOptions
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>SIP Domain</label>
                                                {self.getControlGroup(
                                                    <input type="text" data-testid='customSipDomainFieldId' className="form-control" name="Realm" value={values.Realm} onChange={handleChange} onBlur={handleBlur} />,
                                                    self.getSelect(
                                                        "Visibility.ShowRealm",
                                                        values.Visibility.ShowRealm,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                                {errors.Realm && touched.Realm &&
                                                    <span className="error-message">{errors.Realm}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>SIP Proxy</label>
                                                {self.getControlGroup(
                                                    <input type="text" data-testid='customSipProxyFieldId' className="form-control" name="Proxy" value={values.Proxy} onChange={handleChange} onBlur={handleBlur} />,
                                                    self.getSelect(
                                                        "Visibility.ShowProxy",
                                                        values.Visibility.ShowProxy,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                                {errors.Proxy && touched.Proxy &&
                                                    <span className="error-message">{errors.Proxy}</span>}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={SBCMode.isValueValid ? 'EditMandatory' : 'Locked'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'SBCMode',
                                                            Label: 'Authentication Type',
                                                            Values: SBCModeOptions,
                                                            Placeholder: 'Select...',
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />,
                                                    self.getSelect(
                                                        "Visibility.ShowSBCMode",
                                                        values.Visibility.ShowSBCMode,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                                {errors.SBCMode && touched.SBCMode &&
                                                    <span className="error-message">{errors.SBCMode}</span>}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Show Username/Password</label>
                                                {self.getSelect(
                                                    "Visibility.ShowCredentials",
                                                    values.Visibility.ShowCredentials,
                                                    setFieldValue,
                                                    ShowOptions
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Show Auth Username</label>
                                                {self.getSelect(
                                                    "Visibility.ShowAuthUsername",
                                                    values.Visibility.ShowAuthUsername,
                                                    setFieldValue,
                                                    ShowOptions
                                                )}
                                            </div>
                                        </div>
                                        <div className='user-form-parts-wrapper'>
                                            {self.props.baseAccount.roles
                                                && canAccess('CertificationLevel', self.props.baseAccount.roles) &&
                                                <div className="form-group">
                                                    <div className="alert system-owner-container">
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'select',
                                                                Id: 'CertificationName',
                                                                Label: (
                                                                    <div>
                                                                        <i
                                                                            className="fa-solid fa-user-secret"
                                                                            title="System Owner Only"
                                                                        />Certification Level
                                                                        <small className='fw-normal'> (System Owner Only)</small>
                                                                    </div>),
                                                                Values: CertificationLevelOptions,
                                                                Placeholder: 'Select...',
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={[]}
                                                            setFieldValue={setFieldValue} />

                                                    </div>
                                                </div>

                                            }
                                            {this.renderDedicatedIPTag({ values, touched, setFieldValue, errors })}
                                        </div>



                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <AllowedIPs
                                                    formValues={values}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    serviceName={values.ServiceVariantCode}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Show Allowed IPs</label>
                                                {self.getSelect(
                                                    "Visibility.ShowAllowedIPs",
                                                    values.Visibility.ShowAllowedIPs,
                                                    setFieldValue,
                                                    ShowOptions)}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper hidden">
                                            <div className="form-group">
                                                <label>Show Service Sync Module</label>
                                                {self.getSelect(
                                                    "Visibility.ShowServiceSyncModule",
                                                    values.Visibility.ShowServiceSyncModule,
                                                    setFieldValue,
                                                    ShowOptions)}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Expiry (seconds)</label>
                                                {self.getControlGroup(
                                                    <input type="number" className="form-control" name="Expiry" value={values.Expiry} onChange={handleChange} onBlur={handleBlur} />,
                                                    self.getSelect(
                                                        "Visibility.ShowExpiry",
                                                        values.Visibility.ShowExpiry,
                                                        setFieldValue,
                                                        ShowOptions
                                                    ))}
                                            </div>
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'Protocol',
                                                            Label: 'Protocol',
                                                            Values: ProtocolOptions,
                                                            HelpTitle: 'Protocol',
                                                            Madatory: false,
                                                            Help: (
                                                                <>
                                                                    <p>Note: For TLS the realm or proxy you enter must have a valid certificate and support TLS v1.2.</p>
                                                                    <p>A valid certificate is one that is in-date, matches the hostname (or offers a suitable wildcard) and is publicy trusted (via PKI).</p>
                                                                </>
                                                            ),
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />,
                                                    self.getSelect(
                                                        "Visibility.ShowProtocol",
                                                        values.Visibility.ShowProtocol,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'TransferMode',
                                                            Label: 'Propagate Refer',  //isTrunk, canSelectTrueTransfer, trueTransferSupported, transferMode, baseAccountRoles
                                                            Values: getTransferModeOptions(
                                                                isTrunk,
                                                                self.props.canSelectTrueTransfer || self.props.trueTransferSupported,
                                                                values.TransferMode,
                                                                self.props.baseAccountInfo.Roles,
                                                                self.props.account.BrandShortName),
                                                                HelpTitle: 'Propagate Refer',
                                                                Help: (
                                                                    <>
                                                                        <p>Select '{values.ServiceVariantCode} handles transfers' to propagate received SIP REFER messages
                                                                            from Microsoft upstream to this service. If set to 'Teams handles transfers' then
                                                                            transfers are bridged out as new calls.</p>
                                                                        <p>'{values.ServiceVariantCode}  handles transfers' should be selected if you have users in a Call
                                                                            Center, but otherwise select 'Teams handles transfers' as this will allow consultative
                                                                            transfers to merge call legs.</p>
                                                                    </>)
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />,
                                                    self.getSelect(
                                                        "Visibility.ShowTransferMode",
                                                        values.Visibility.ShowTransferMode,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                            </div>
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'SuppressContactDataParam',
                                                            Label: 'Suppress Contact Data Param',
                                                            Values: YesNoOptions,
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />,
                                                    self.getSelect(
                                                        "Visibility.ShowSuppressContactDataParam",
                                                        values.Visibility.ShowSuppressContactDataParam,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                {self.getControlGroup(
                                                    <FieldWithDisposition
                                                        visibility={'EditMandatory'}
                                                        formObject={{
                                                            Type: 'select',
                                                            Id: 'EncryptMedia',
                                                            Label: 'Encrypt Media',
                                                            Values: YesNoOptions,
                                                        }}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={[]}
                                                        setFieldValue={setFieldValue} />,
                                                    self.getSelect(
                                                        "Visibility.ShowEncryptMedia",
                                                        values.Visibility.ShowEncryptMedia,
                                                        setFieldValue,
                                                        ShowOptions)
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <FieldWithDisposition
                                                    visibility={'EditOptional'}
                                                    formObject={{
                                                        Type: 'sortablelist',
                                                        Id: 'AllowedCodecs',
                                                        Label: 'Override Codecs',
                                                        Values: allCodecs(),
                                                        Placeholder: 'Pass Through All Codecs',
                                                        HelpTitle: 'Override Codecs',
                                                        Help: (
                                                            <>
                                                                <p>Leave blank to pass through all available codecs for each side of the call.</p>
                                                                <p>If specific codecs are selected here only those will be allowed in the SDP payload, other codecs will be removed.</p>
                                                                <p>You can drag and drop the codecs shown to specify the codec preference.</p>
                                                            </>
                                                        ),
                                                    }}
                                                    formValues={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Show Override Codecs</label>
                                                {self.getSelect(
                                                    "Visibility.ShowAllowedCodecs",
                                                    values.Visibility.ShowAllowedCodecs,
                                                    setFieldValue,
                                                    ShowOptions
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <FieldWithDisposition
                                                    visibility={'EditOptional'}
                                                    formObject={getCallPolicyFormObject(
                                                        function (setFieldValue, newValue) {
                                                            if (!newValue || newValue.length === 0 || (newValue.length === 1 && newValue[0] === '')) {
                                                                // Reset to Teams 'defaults' if no override/manage values supplied
                                                                setFieldValue('VMCallingPolicy', 'VM');
                                                                setFieldValue('MOHCallingPolicy', 'MOHTEAMS');
                                                            } else if (values && values['OriginalCallingPolicy']) {
                                                                // Selected to manage values, restore to any supplied values in content
                                                                var splitPolicy = values['OriginalCallingPolicy'].split('_');
                                                                setFieldValue('VMCallingPolicy', splitPolicy[0]);
                                                                setFieldValue('MOHCallingPolicy', splitPolicy[1]);
                                                            }
                                                        }
                                                    )}
                                                    formValues={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </div>
                                            {values.DefaultCallingPolicy && values.DefaultCallingPolicy.includes('custom') &&
                                                <div className="form-group">
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={getCallPolicyVMFormObject(values)}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={errors}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </div>
                                            }
                                            {values.DefaultCallingPolicy && values.DefaultCallingPolicy.includes('custom') &&
                                                <div className="form-group">
                                                    <FieldWithDisposition
                                                        visibility={'EditOptional'}
                                                        formObject={getCallPolicyMOHFormObject(values, (isTrunk ? 'Trunk' : 'PBX'))}
                                                        formValues={values}
                                                        touched={touched}
                                                        errors={errors}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label>Show Calling Policy</label>
                                                {
                                                    self.getSelect(
                                                        "Visibility.ShowCallingPolicy",
                                                        values.Visibility.ShowCallingPolicy,
                                                        setFieldValue,
                                                        ShowOptions)
                                                }
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <FieldWithDisposition
                                                    visibility={'EditOptional'}
                                                    formObject={{
                                                        Type: 'select',
                                                        Id: 'AllowReadSBCAllocation',
                                                        Label: 'Show Assigned SBCs',
                                                        Values: AllowReadSBCAllocationOptions,
                                                        Placeholder: 'Select...',
                                                        HelpTitle: 'Show Assigned SBCs',
                                                        Help: (
                                                            <>
                                                                <p>Setting this to true will
                                                                    allow end customers to
                                                                    see the SBCs (including IP address)
                                                                    that they have been assigned.
                                                                    If set to false end customers
                                                                    will not be shown this information
                                                                    but it will still be
                                                                    visible to template owners.</p>
                                                            </>
                                                        ),
                                                    }}
                                                    formValues={values}
                                                    touched={touched}
                                                    errors={[]}
                                                    setFieldValue={setFieldValue} />
                                                {errors.AllowReadSBCAllocation && touched.AllowReadSBCAllocation &&
                                                    <span className="error-message">{errors.AllowReadSBCAllocation}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>Show Extension</label>
                                                {
                                                    self.getSelect(
                                                        "Visibility.ShowExtension",
                                                        values.Visibility.ShowExtension,
                                                        setFieldValue,
                                                        ShowOptions)
                                                }
                                            </div>
                                        </div>
                                        {isTrunk ?
                                            <div className="user-form-parts-wrapper">
                                                <div className="form-group">
                                                    {self.getControlGroup(
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'select',
                                                                Id: 'FromHeaderType',
                                                                Label: 'From Header',
                                                                Values: getFromHeaderTypeTrunkOptions(
                                                                    'No default selected...',
                                                                    ((values.FromHeaderType && values.FromHeaderType.includes('_')) || isSystemOwner)
                                                                ),
                                                                HelpTitle: 'From SIP Header Format',
                                                                Help: (
                                                                    <ul>
                                                                        <li><strong>Trunk Caller Id</strong>: The phone number assigned to the user in the portal</li>
                                                                        <li><strong>SIP Identifier</strong>: The SIP username</li>
                                                                        <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams)</li>
                                                                    </ul>
                                                                ),
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={[]}
                                                            setFieldValue={setFieldValue} />,
                                                        self.getSelect(
                                                            "Visibility.ShowFromHeaderType",
                                                            values.Visibility.ShowFromHeaderType,
                                                            setFieldValue,
                                                            ShowOptions)
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    {self.getControlGroup(
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'select',
                                                                Id: 'PAIHeaderType',
                                                                Label: 'P-Asserted-Identity Header',
                                                                Values: getPAIHeaderTypeTrunkOptions(
                                                                    'No default selected...',
                                                                    ((values.PAIHeaderType && values.PAIHeaderType.includes('_')) || isSystemOwner)
                                                                ),
                                                                HelpTitle: 'P-Asserted-Identity SIP Header Format',
                                                                Help: (
                                                                    <ul>
                                                                        <li><strong>Not Present</strong>: Do not set any P-Asserted-Identity header</li>
                                                                        <li><strong>Trunk User Number</strong>: The phone number assigned to the user in the portal</li>
                                                                        <li><strong>SIP Identifier</strong>: The SIP username</li>
                                                                        <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams)</li>
                                                                    </ul>
                                                                ),
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={[]}
                                                            setFieldValue={setFieldValue} />,
                                                        self.getSelect(
                                                            "Visibility.ShowPAIHeaderType",
                                                            values.Visibility.ShowPAIHeaderType,
                                                            setFieldValue,
                                                            ShowOptions)
                                                    )}
                                                </div>
                                            </div>
                                            : null}
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <FieldWithDisposition
                                                    visibility={'EditMandatory'}
                                                    formObject={{
                                                        Type: 'select',
                                                        Id: 'E164Mode',
                                                        Label: 'E164 Number Format',
                                                        Values: E164ModeOptions,
                                                    }}
                                                    formValues={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue} />
                                            </div>
                                            <div className="form-group">
                                                <label>Show Dialing Options&nbsp;
                                                    <button className="btn btn-link btn--faux-link" onClick={(e) => {
                                                        e.preventDefault();
                                                        self.toggleHelp("showDialingPrefixHelp");
                                                        return false;
                                                    }}>
                                                        <i className="fa-solid fa-question-circle" id="DialingPrefixesHelp"></i>
                                                    </button>
                                                    {self.state.showDialingPrefixHelp ?
                                                        <TeachingBubble
                                                            target={'#DialingPrefixesHelp'}
                                                            hasCondensedHeadline={true}
                                                            onDismiss={() => self.dismissHelp('showDialingPrefixHelp')}
                                                            hasCloseIcon={true}
                                                            closeButtonAriaLabel="Close"
                                                            headline={'Dialing Options Visibility Impacts:'}>
                                                            <ul>
                                                                <li>E164 Number Format.</li>
                                                                <li>E164 Number Translation (used for Localized number formats).</li>
                                                                <li>Outside Line Prefix.</li>
                                                            </ul>
                                                        </TeachingBubble>
                                                        : null}
                                                </label>
                                                {self.getSelect(
                                                    "Visibility.ShowDialingPrefixes",
                                                    values.Visibility.ShowDialingPrefixes,
                                                    setFieldValue,
                                                    ShowOptions
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <FieldWithDisposition
                                                    visibility={'EditOptional'}
                                                    formObject={{
                                                        Id: "ApplyPAIDPrefix",
                                                        Label: 'Apply PAID Prefix',
                                                        Type: 'checkbox',
                                                        Group: 'ApplyPAIDPrefix',
                                                        CheckedLabel: 'Prefix applied',
                                                        CheckedValue: 'checked',
                                                        VisibilityKey: 'ApplyPAIDPrefix',
                                                        HelpTitle: 'Apply PAID Prefix',
                                                        Help: (
                                                            <>
                                                                <p>Check this box to toggle number formatting on the request headers.</p>
                                                                <p> Enabling this will remove the leading plus sign from numbers in the FROM and PAI headers of the request.</p>
                                                            </>
                                                        )
                                                    }}
                                                    formValues={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Show Apply PAID Prefix</label>
                                                {
                                                    self.getSelect(
                                                        "Visibility.ShowApplyPAIDPrefix",
                                                        values.Visibility.ShowApplyPAIDPrefix,
                                                        setFieldValue,
                                                        ShowOptions)
                                                }
                                            </div>
                                        </div>
                                        {self.getE164Render(
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            self.getE164Inputs(),
                                            values.ServiceVariantCode === "TRUNK")}


                                        {values.Visibility.ShowEmergencyTrunks && (
                                        <div>
                                            <hr />
                                            <div
                                            className={
                                                (self.hasEmergencyTrunkFeature() || self.hasBrandContributorRole()) 
                                                ? "user-form-parts-wrapper"
                                                : "alert system-owner-container w-50"
                                            }
                                            >
                                                <div className="form-group">
                                                    <EmergencyDropDown
                                                    systemOwnerMode={!self.hasEmergencyTrunkFeature() && !self.hasBrandContributorRole()}
                                                    accountId={self.props.account.Id}
                                                    emergencyAccountServiceId={
                                                        values.EmergencyAccountServiceId
                                                    }
                                                    onChangeCallBack={(opt) => {
                                                        setFieldValue(
                                                        "EmergencyAccountServiceId",
                                                        opt.Id
                                                        );
                                                    }}
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        )}

                                        <p className="lead">Optional labels</p>
                                        <p>These can be used to name fields in the portal to match the terminology used in other systems.</p>

                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Identifier Label</label>
                                                <input type="text" className="form-control" name="Labels.IdentifierLabel" value={values.Labels.IdentifierLabel} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            <div className="form-group">
                                                <label>Auth Username Label</label>
                                                <input type="text" className="form-control" name="Labels.AuthUsernameLabel" value={values.Labels.AuthUsernameLabel} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper">
                                            <div className="form-group">
                                                <label>Realm Label</label>
                                                <input type="text" className="form-control" name="Labels.RealmLabel" value={values.Labels.RealmLabel} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            <div className="form-group">
                                                <label>Proxy Label</label>
                                                <input type="text" className="form-control" name="Labels.ProxyLabel" value={values.Labels.ProxyLabel} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                        </div>
                                        <div className="user-form-parts-wrapper hidden">
                                            <div className="form-group">
                                                <label>Logo Url</label>
                                                <input type="text" className="form-control" name="LogoURL" value={values.LogoURL} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="user-form-action-buttons">
                                                    <button className="btn right-action-button mb-2 close-form" onClick={(e) => {
                                                        e.preventDefault();
                                                        self.goToTemplates(self.props.account.Id);
                                                        return false;
                                                    }}>{self.props.leaveButtonText}</button>
                                                    <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}>{self.props.submitButtonText}</SubmitButton>
                                                    {self.props.deleteAction &&
                                                        <button type="button" className="btn btn-default"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                self.onDeleteTemplate(self.props.templateInUse);
                                                                return false;
                                                            }}><i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </>
                    )
                }
                }
            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    const services = state.services;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccountInfo: account.baseAccountInfo,
        canSelectTrueTransfer: services.canSelectTrueTransfer,
        trueTransferSupported: services.trueTransferSupported 
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandingTemplateForm));
