import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ErrorReport from './ErrorReport';
import Offline from './components/speciality/Offline';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { getMsalAuthContext } from './MSALAuthProvider';
import { getLastAzureAccountLocalStorage, clearLastAzureAccountLocalStorage } from './CallMSAPI';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { LoadingSplash, ErrorSplash } from './CallMSUIHelpers';

import 'bootstrap3/dist/css/bootstrap.css';
import './index.css';
import { connect } from 'react-redux';

import Layout from './Layout';
import { withRouter } from 'react-router-dom';

initializeIcons();

// About to log in for first time in this 'session', ensure loginHint is set
var pref = getLastAzureAccountLocalStorage();
if (pref) {
    console.log("Logging in with preffered username: " + pref);
    // Ditch the local storage. Base Account will set it again if applicable.
    clearLastAzureAccountLocalStorage();

    // Sanity check the local storage has gone
    var oldLocalStorageItem = getLastAzureAccountLocalStorage();
    if (oldLocalStorageItem) {
        console.log("cmsLastAccount Unable to clear entry");
    } else {
        console.log("cmsLastAccount username entry cleared");
    }
}

var authProvider = getMsalAuthContext(pref);

class App extends Component {

    constructor(props) {
        super(props);
    }

    renderAuthLogic = (err) => {
        return <Layout />;
    }


    renderUnauthLogic = (login, err) => {
        if (err) {
            return (
                <ErrorSplash>
                    {err}
                    <button onClick={login} className="btn btn-default">Retry Login</button>
                </ErrorSplash>
            );
        } else {
            return (<LoadingSplash text="Logging In" />);
        }
    }


    render() {
        return (
            <ErrorReport>
                <Offline />
                <AzureAD provider={authProvider} forceLogin={true}>
                    {
                        ({ login, logout, authenticationState, error, accountInfo }) => {
                            var errorBlock = null;
                            if (error) {
                                errorBlock = (
                                    <div>
                                        <p><strong>Error Processing Login</strong></p>
                                        <p style={{ wordWrap: 'break-word' }}>
                                            <span style={{ fontWeight: 'bold' }}>Code:</span> {error.errorCode}
                                        </p>
                                        <p style={{ wordWrap: 'break-word' }}>
                                            {error.errorMessage}
                                        </p>
                                    </div>
                                );
                            }
                            switch (authenticationState) {
                                case AuthenticationState.Authenticated:
                                    return this.renderAuthLogic(errorBlock);
                                case AuthenticationState.Unauthenticated:
                                    return this.renderUnauthLogic(login, errorBlock);
                                case AuthenticationState.InProgress:
                                    return (
                                        <LoadingSplash text="Authentication In Progress">
                                            {errorBlock}
                                        </LoadingSplash>
                                    );
                                default:
                                    return (
                                        <ErrorSplash>
                                            {errorBlock}
                                          Authentication state: {authenticationState}
                                        </ErrorSplash>
                                    );
                            }
                        }
                    }
                </AzureAD>
            </ErrorReport>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        loaded: account.loaded,
        account: account.account,
        baseAccountsLoaded: account.baseAccountsLoaded
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));