import React, { Component } from 'react';

export class SubmitButton extends Component {
    render() {
        var { isSubmitting, ...other } = this.props;
        var isDisabled = this.props.isSubmitting;
        if (this.props.hasOwnProperty('forceDisable')) {
            isDisabled = (this.props.isSubmitting || this.props.forceDisable)
        }
        return (
            <button disabled={isDisabled} className={"btn btn-primary mb-2"} {...other} >{this.props.isSubmitting ? (<> <i className="fa-solid fa-spinner"></i> Loading </>) : this.props.children}
            </button>
        );
    }
}