import React, { Component } from 'react';
import axios from 'axios';
import { getMsalConfig, getMsalAuthContext } from './MSALAuthProvider';
import { cmsGetSyncModuleDefaultSettings } from './CallMSAPI';
import { openPopupWindow } from './ExternalAuthProvider.js';
import { extractIdToken } from './utils/ExtractIdToken.js';
import { extractSpecificLicence } from './LicenceHelpers';

const baseURL = "https://graph.microsoft.com/v1.0";
const servicePrincipalURL = "https://graph.microsoft.com/v1.0/servicePrincipals";
const appId = "48ac35b8-9aa8-4d74-927d-1f4a14a0b239";
const adminRoleId = "62e90394-69f5-4237-9190-012177145e10";
const AppServicePrincipalWriteScope = "Application.ReadWrite.All";
const DirectoryReadAllScope = 'Directory.Read.All';

export const GroupReadScope = 'Group.Read.All';

export function silentlyGetAccessToken(reqArgs, popupWindow, rejectCb) {

    var authenticationContext = getMsalAuthContext();
    var scopes = ['openid', 'profile'];
    if (reqArgs.additionalScope) {
        scopes.push(reqArgs.additionalScope);
    }
    var tokenRequest = { scopes: scopes };

    return authenticationContext.acquireTokenSilent(tokenRequest)
        .then(response => {
            return response.accessToken;
        }).catch(err => {
            return getAccessToken(reqArgs, popupWindow, rejectCb);
        });
}

export function getAccessToken(reqArgs, popupWindow, rejectCb) {

    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);
        var conf = getMsalConfig();
        var scopes = ['openid', 'profile'];

        // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
        var nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        var syncSettings = reqArgs.SyncModuleDefaultSettings;

        var url = "https://login.microsoftonline.com/" + syncSettings.TenantId + "/oauth2/v2.0/authorize?"
            + "client_id=" + conf.auth.clientId
            + "&response_type=id_token%20token"
            + "&response_mode=fragment"
            + "&prompt=select_account"
            + "&redirect_uri=" + redirectUriComp
            + "&nonce=" + nonce;

        if (reqArgs.additionalScope) {
            scopes.push(reqArgs.additionalScope);
        }

        if (scopes.length) {
            url += '&scope=' + scopes.join('+');
        }


        if (!popupWindow) {
            popupWindow = openPopupWindow(url, "Portal Auth");
        } else {
            popupWindow.location.href = url;
        }

        let pollTimer = window.setInterval(function () {
            if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                window.clearInterval(pollTimer);
                rejectCb && rejectCb();
            } else {
                try {
                    var loc = popupWindow.location;
                    if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {

                        var token = loc.hash;
                        var idToken = token.split('id_token=')[1];
                        var temp = extractIdToken(idToken);
                        var tokens = {
                            idToken: temp,
                            accessToken: null
                        };

                        if (token.includes('access_token=')) {
                            var accessToken = token.split('&token_type=')[0].replace('#access_token=', '');
                            tokens.accessToken = accessToken;
                            window.clearInterval(pollTimer);

                            popupWindow.close();
                            resolve(tokens);
                        } else {
                            popupWindow.close();
                            rejectCb && rejectCb();
                        }
                    }
                } catch (e) {
                    // whilst not our origin it'll error, just ignore until it's back to our origin
                }
            }
        }, 100);
    })
}

export function getMyAdminGroups(token) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    return axios({
        headers: headers,
        url: baseURL + '/me/memberOf',
        method: 'get'
    });
}
export function getOrganisationGroups(token, input = '', nextUrl = null, pageSize = '10') {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    var url = baseURL + '/groups';

    url += '?$top=' + pageSize;
    if (input && input !== '') {
        url += '&$filter=startswith(displayName,\'' + input + '\')';
    }

    if (nextUrl) {
        console.log("Using next URL: " + nextUrl);
        url = nextUrl;
    }

    return axios({
        headers: headers,
        url: url,
        method: 'get'
    });
}
export function getSubscribedSKUs(token) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    return axios({
        headers: headers,
        url: baseURL + '/subscribedSkus',
        method: 'get'
    });
}
export function checkIfSpareSKUs(tok) { //One or two spare Office 365 user licenses such as Business Basic/Premium or E1/E3/E5, for a few hours during the initial setup.
    return getSubscribedSKUs(tok)
        .then(function (res) {
            return evaluateSpareLicenses(tok, res);
        }).catch(function (err) { throw new Error(err);});
}
export function evaluateSpareLicenses(tok, res) {
    var results = {
        res: false,
        data: null
    };
    if (res && res.data && res.data.value.length > 0) {
        results.data = res.data.value;
        var enabledSkus = res.data.value.filter(x => x.prepaidUnits.enabled > 0 && x.prepaidUnits.enabled > x.consumedUnits);

        for (var i = 0; i < enabledSkus.length; i++) {
            var entry = enabledSkus[i];
            var servicePlans = entry.servicePlans;
            var licenses = servicePlans
                .filter(x => x.servicePlanName == 'MCOSTANDARD' || x.servicePlanName == 'MCOSTANDARD_GOV');
            if (licenses.length > 0) {
                results.res = true;
                return results;
            }
        }
        if (res.data.hasOwnProperty('@odata.nextLink')) {
            var headers = {};
            headers.Authorization = "Bearer ".concat(tok);
            return axios({
                headers: headers,
                url: res.data['@odata.nextLink'],
                method: 'get'
            }).then(function (res) {
                return evaluateSpareLicenses(tok, res)
            });
        } else {
            results.res = false;
            return results;
        }
    }
}
export function checkIfUsersAreLicensed(token, account) {  //Microsoft Phone System licence add-ons (or E5 licences) for the end users of the service
    var results = {
        res: false,
        data: null
    };
    return getSubscribedSKUs(token)
        .then(function (res) {
            if (res && res.data && res.data.value.length > 0) {
                results.data = res.data.value;
                for (var i = 0; i < res.data.value.length; i++) {
                    var entry = res.data.value[i];
                    var servicePlans = entry.servicePlans;

                    var phoneServicePlans = servicePlans.filter(x =>
                        x.servicePlanName == 'MCOEV'
                        || x.servicePlanName == 'MCOEV_GOV');

                    if (phoneServicePlans.length > 0) {
                        results.res = true;
                        return results;
                    }
                }
                results.res = false;
                return results;
            }
            else {
                results.res = false;
                return results;
            }
        }).then((res) => {
            var phoneAppLic = extractSpecificLicence(account.Licences, 'USER-PHONEAPP');
            if (phoneAppLic) {
                res.res = true;
            }
            return res;
        }).catch(function (err) {
            return err;
        });
}
export function checkIfTeamsPhoneResourceAccount(token, account) {  //Microsoft Teams Phone Resoure Account for Phone App V3
    var results = {
        res: false,
        data: null
    };
    return getSubscribedSKUs(token)
        .then(function (res) {
            if (res && res.data && res.data.value.length > 0) {
                let match = res.data.value.find(x => x.skuPartNumber === 'PHONESYSTEM_VIRTUALUSER');
                if (match) {
                    let consumed = match.consumedUnits;
                    let enabled = match.prepaidUnits.enabled;
                    if (enabled > consumed) {
                        results.res = true;
                        results.data = match;
                        return results;
                    }
                }
                results.res = false;
                results.data = null;
                return results;
            }
            else {
                results.res = false;
                results.data = null;
                return results;
            }
        }).catch(function (err) {
            return err;
        });
}
export function checkIfGlobalAdmin(token) {
    return getMyAdminGroups(token).then(function (results) {
        if (results.data && results.data.value && results.data.value.length > 0) {
            return evaluateAdminRoles(token, results);
        }
    }).catch(function (err) {
        throw new Error(err);
    });
}
export function evaluateAdminRoles(tok, res) {
    var results = {
        res: false,
        data: null
    };
    if (res.data && res.data.value && res.data.value.length > 0) {
        results.data = res.data.value;
        var isAdmin = res.data.value.filter(x => x.roleTemplateId == adminRoleId);
        if (isAdmin && isAdmin.length > 0) {
            results.res = true;
            return results;
        } else {
            if (res.data.hasOwnProperty('@odata.nextLink')) {
                var headers = {};
                headers.Authorization = "Bearer ".concat(tok);
                return axios({
                    headers: headers,
                    url: res.data['@odata.nextLink'],
                    method: 'get'
                }).then(function (res) {
                    return evaluateAdminRoles(tok, res)
                });
            } else {
                results.res = false;
                return results;
            }
        }
    } else {
        results.res = false;
        return results;
    }
}
export function checkServicePrincipalPresent(token) {

    var headers = {};
    headers.Authorization = "Bearer ".concat(token);

    var results = {
        res: false,
        data: null
    };

    return axios({
        headers: headers,
        url: servicePrincipalURL + "?$filter=appId eq '" + appId + "'",
        method: 'get'
    }).then(function (result) {
        if (result) {
            results.data = result.data.value;
            var principals = result.data.value;
            if (principals.length == 0) {
                results.res = false;
                return results;
            } else {
                results.res = true;
                return results;
            }
        }
    });
}
export function createServicePrincipal(token) {

    var servicePrincipal = {
        appId: appId
    };

    var headers = {};
    headers.Authorization = "Bearer ".concat(token);

    return axios({
        headers: headers,
        url: servicePrincipalURL,
        method: 'post',
        data: servicePrincipal
    });
}
export function deleteServicePrincipal(token) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);

    return axios({
        headers: headers,
        url: servicePrincipalURL + "?$filter=appId eq '" + appId + "'",
        method: 'get'
    }).then(function (result) {
        if (result) {
            var principals = result.data.value;
            if (principals.length > 0) {
                return axios({
                    headers: headers,
                    url: servicePrincipalURL + '/' + principals[0].id,
                    method: 'delete'
                });
            }
        }
    })
}
export function findTestUserPatti(token) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    var userId = '';

    return axios({
        headers: headers,
        url: baseURL + '/users',
        method: 'get'
    }).then(function (res) {
        if (res && res.data && res.data.value) {
            var users = res.data.value;
            var pattyF = users.filter(x => /^PattiF@M365/i.test(x.mail) && /.OnMicrosoft.com$/i.test(x.mail));
            if (pattyF.length > 0) {
                userId = pattyF[0].id;
                return findUsersLicenses(token, pattyF[0].id);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }).then(function (res) {
        if (res && res.data && res.data.value && res.data.value.length > 1) {
            return { isPresent: true, id: userId, licenses: res.data.value, data: res.data.value };
        } else {
            return { isPresent: false, id: null, licenses: null, data: null };
        }
    });
}
export function findUsersLicenses(token, id) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    return axios({
        headers: headers,
        url: baseURL + '/users/' + id + '/licenseDetails',
        method: 'get'
    });
}
export function removeUserLicenses(token, id, data) {
    var headers = {};
    headers.Authorization = "Bearer ".concat(token);
    return axios({
        headers: headers,
        url: baseURL + '/users/' + id + '/assignLicense',
        method: 'post',
        data: data
    })
}
export function checkDelicenceTestUser(reqArg) {
    var tokens = null;
    var userId = '';
    return getAccessToken(reqArg, null, null).then(function (tok) {
        tokens = tok;
        return findTestUserPatti(tokens.accessToken)
    }).then(function (res) {
        if (res && res.isPresent != false && res.licenses && res.licenses.length > 1) {
            userId = res.id;
            var licenses = res.licenses;
            var removeLicenses = [];
            for (var i = 0; i < licenses.length; i++) {
                removeLicenses.push(licenses[i].skuId);
            }
            var data = {
                addLicenses: [],
                removeLicenses: removeLicenses
            };
            return removeUserLicenses(tokens.accessToken, userId, data)
        } else {
            throw new Error('Could not find PattiF license details');
        }
    }).then(function (res) {
        return tokens;
    }).catch(function (err) {
        throw new Error(err);
    });
}

export function getWizardPreReqs(account, incorrectPermissionsCb, token, reqArg) {
    var results = {
        isGlobalAdmin: false,
        o365Licenses: false,
        userPhoneLicenses: false,
        teamsResourceAccount: false,
        servicePrinc: false,
        isPattiFPresent: false,
        idToken: null,
        isGlobalAdminFullData: null,
        o365LicensesFullData: null,
        userPhoneLicensesFullData: null,
        servicePrincFullData: null,
        isPattiFPresentFullData: null
    };
    var tokens = null;
    if (token && token.accessToken) {
        results.idToken = token.idToken;
        return checkIfGlobalAdmin(token.accessToken)
            .then(function (res) {
                if (res && res.res == true) {
                    results.isGlobalAdmin = true;
                }
                if (res && res.data) {
                    results.isGlobalAdminFullData = res.data;
                }
                return checkIfSpareSKUs(token.accessToken)  //One or two spare Office 365 user licenses such as Business Basic/Premium or E1/E3/E5, for a few hours during the initial setup.
            }).then(function (res) {
                if (res && res.res == true) {
                    results.o365Licenses = true;
                }
                if (res && res.data) {
                    results.o365LicensesFullData = res.data;
                }
                return checkIfTeamsPhoneResourceAccount(token.accessToken, account)
            }).then(function (res) {
                if (res && res.res == true) {
                    results.teamsResourceAccount = true;
                }
                if (res && res.data) {
                    results.teamsResourceAccount = res.data;
                }
                return checkIfUsersAreLicensed(token.accessToken, account)  //Microsoft Phone System licence add-ons (or E5 licences) for the end users of the service
            }).then(function (res) {
                if (res && res.res == true) {
                    results.userPhoneLicenses = true;
                }
                if (res && res.data) {
                    results.userPhoneLicensesFullData = res.data;
                }
                return checkServicePrincipalPresent(token.accessToken);
            }).then(function (res) {
                if (res && res.res == true) {
                    results.servicePrinc = true;
                }
                if (res && res.data) {
                    results.servicePrincFullData = res.data;
                }
                return findTestUserPatti(token.accessToken);
            }).then(function (res) {
                if (res && res.isPresent != false) {
                    results.isPattiFPresent = true;
                }
                if (res && res.data) {
                    results.isPattiFPresentFullData = res.data;
                }
                return results;
            }).catch(function (err) {
                throw new Error(err);
            })
    } else {
        return getAccessToken(reqArg, null, incorrectPermissionsCb).then(function (tok) {
            tokens = tok;
            results.idToken = tok;
            return checkIfGlobalAdmin(tokens.accessToken)  //A user/login to your Office 365 account with Global Admin rights.
        }).catch(function (err) {
            throw new Error(err);
        }).then(function (res) {
            if (res && res.res == true) {
                results.isGlobalAdmin = true;
            }
            if (res && res.data) {
                results.isGlobalAdminFullData = res.data;
            }
            return checkIfSpareSKUs(tokens.accessToken)  //One or two spare Office 365 user licenses such as Business Basic/Premium or E1/E3/E5, for a few hours during the initial setup.
        }).then(function (res) {
            if (res && res.res == true) {
                results.o365Licenses = true;
            }
            if (res && res.data) {
                results.o365LicensesFullData = res.data;
            }
            return checkIfTeamsPhoneResourceAccount(tokens.accessToken, account)
        }).then(function (res) {
            if (res && res.res == true) {
                results.teamsResourceAccount = true;
            }
            if (res && res.data) {
                results.teamsResourceAccount = res.data;
            }
            return checkIfUsersAreLicensed(tokens.accessToken, account)  //Microsoft Phone System licence add-ons (or E5 licences) for the end users of the service
        }).then(function (res) {
            if (res && res.res == true) {
                results.userPhoneLicenses = true;
            }
            if (res && res.data) {
                results.userPhoneLicensesFullData = res.data;
            }
            return checkServicePrincipalPresent(tokens.accessToken);
        }).then(function (res) {
            if (res && res.res == true) {
                results.servicePrinc = true;
            }
            if (res && res.data) {
                results.servicePrincFullData = res.data;
            }
            return findTestUserPatti(tokens.accessToken);
        }).then(function (res) {
            if (res && res.isPresent != false) {
                results.isPattiFPresent = true;
            }
            if (res && res.data) {
                results.isPattiFPresentFullData = res.data;
            }
            return results;
        }).catch(function (err) {
            throw new Error(err);
        })
    }
}

export class Msgraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returned: null,
            SyncModuleDefaultSettings: {
                SyncClientId: null,
                TenantId: null
            }
        };
        this.returnFunctionResults = this.returnFunctionResults.bind(this);
    }

    returnFunctionResults(func) {
        var self = this;
        func().then(function (results) {
            self.setState({ returned: JSON.stringify(results) });
        }).catch(err => console.log(err));
    }

    componentDidMount() {
        var self = this;
        cmsGetSyncModuleDefaultSettings(this.props.account.Id).then(function (res) {
            var appId = res.data.find(x => x.Key === "SyncClientId").Value;
            var tenantId = res.data.find(x => x.Key === "TenantId").Value;
            if (tenantId === null) {
                tenantId = 'common';
            }
            var syncSettings = {
                SyncClientId: appId,
                TenantId: tenantId
            };
            self.setState({ SyncModuleDefaultSettings: syncSettings });
        })
    }

    render() {
        var reqArg = {
            SyncModuleDefaultSettings: this.state.SyncModuleDefaultSettings,
            additionalScope: AppServicePrincipalWriteScope
        };

        return (
            <>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken().then(function (tok) {
                            return checkIfGlobalAdmin(tok.accessToken)
                        })
                    })}> Check If Global Admin
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken().then(function (tok) {
                            return checkIfUsersAreLicensed(tok.accessToken, this.props.account)
                        })
                    })}> Check If Users have Phone System license
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken().then(function (tok) {
                            return checkIfSpareSKUs(tok.accessToken)
                        })
                    })}> get subscribed skus
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken().then(function (tok) {
                            return checkServicePrincipalPresent(tok.accessToken)
                        })
                    })}> Is Service Principal present
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken(reqArg, null, null).then(function (tok) {
                            return createServicePrincipal(tok.accessToken)
                        })
                    })}> Create Service Principal
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(function () {
                        return getAccessToken(reqArg, null, null).then(function (tok) {
                            return deleteServicePrincipal(tok.accessToken)
                        })
                    })}> Delete Service Principal
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => this.returnFunctionResults(getWizardPreReqs)}> Get wizard prereqs
                    </button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button type="button" className="btn btn-primary" onClick={() => { cmsGetSyncModuleDefaultSettings(this.props.account.Id) }}> get App Settings
                    </button>
                </div>
                <div>{this.state.returned}</div>
            </>
        );
    }
}
