import React, { Component } from 'react';
import { cmsGetUsers } from '../CallMSAPI.js';
import { getPbxLabel, serviceCodeToIcon, serviceCodeTypeToIcon, canAccess } from '../CallMSUIHelpers.js'

import ReactTable from "react-table";
import "react-table/react-table.css";
import CallLight from "./CallLight";
import EditUserForm from './EditUserForm';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import UserPBXRegistrationLight from './UserPBXRegistrationLight.js';

var _ = require('lodash');


class UserReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            updateTime: new Date().getTime(),
            pageCount: -1,
            expanded: {},

            // Not specific to react-table
            hasRowExpanded: false,
            filters: {}
        }

        this.updateData = this.updateData.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.closeAllRows = this.closeAllRows.bind(this);
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            // If the table has an open row, skip the update
            if (this.state.hasRowExpanded) {
                console.log("Skipping table update, row is open");
                return;
            } else {
                console.log("Starting an update");
                this.refReactTable.fireFetchData();
            }
        }
    }

    updateData(state, instance) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        var apiParams = {
            'SortBy': 'Part2'
        };

        // Handle sorting/order
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'part1_identifier') {
                apiParams['SortBy'] = 'Part1';
            }

            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Our version of normal search text...
                if (f.id === 'part2_user') {
                    apiParams['part2Identifier'] = f.value;
                    return;
                }

                if (f.id === 'part1_identifier') {
                    apiParams['part1Identifier'] = f.value;
                    return;
                }

                // Next, API level filters
                if (f.id === 'part2_service') {
                    // Need to get the service ID from this.
                    apiParams['part2ServiceId'] = f.value;
                    return;
                }
                if (f.id === 'part1_service') {
                    apiParams['part1AccountServiceId'] = f.value;
                    return;
                }
                if (f.id === 'registration' && f.value !== 'all') {
                    apiParams['onlyBadRegistrations'] = true;
                }
            })
        }

        if (!apiParams['part1AccountServiceId'] && !apiParams['part2ServiceId']) {
            // We supply the serviceId for PBX to allow the sort to work correctly
            var pbxAccService = _.find(self.props.services, { ServiceVariantCode: 'PBX' });
            if (pbxAccService) {
                apiParams['part1ServiceVariantId'] = pbxAccService.ServiceVariantId;
                apiParams['part1ServiceFilterAppliedToUnmatchedOnly'] = true;
            }
        }

        cmsGetUsers(
            self.props.account.Id,
            apiParams,
            function (callmsData) {
                if (state.filtered && state.filtered.length > 0) {
                    if (state.filtered != self.state.filters) {
                        self.closeAllRows();
                    }
                }
                self.setState({
                    data: callmsData.Results,
                    updateTime: new Date().getTime(),
                    pageCount: callmsData.PageCount,
                    loading: false,
                    filters: state.filtered
                });
                self.props.loadCompleteCallback && self.props.loadCompleteCallback();
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    getColumns() {
        var self = this;
        var columns = [];

        var shouldIncludeServiceColumn = self.props.services.filter(x => x.ServiceCode != 'pbx');

        columns.push(
            {
                id: "part2_user",
                Header: "User",
                accessor: function (d) {
                    if (!d.Part2) {
                        return <span className="unattached-span">Unattached</span>
                    } else {
                        if (d.Part2 && d.Part2.hasOwnProperty('Name') && d.Part2.Name) {
                            return d.Part2.Name;
                        } else {
                            return d.Part2.Identifier;
                        }
                    }
                }
            }
        );

        if (shouldIncludeServiceColumn && shouldIncludeServiceColumn.length > 1) {

            columns.push(
                {
                    id: "part2_service",
                    Header: "Service",
                    sortable: false,
                    className: 'text-center',
                    accessor: function (d) {
                        if (d.Part2) {
                            var service = _.find(self.props.services, function (s) { return s.Id === d.Part2.AccountServiceId });
                            if (service) {
                                return serviceCodeToIcon(service.ServiceCode);
                            }
                        }
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "true") {
                            return true;
                        }
                        return false;
                    },
                    Filter: ({ filter, onChange }) => self.serviceFilter(
                        _.filter(self.props.services, function (s) { return s.ServiceCode !== 'pbx' }),
                        filter,
                        onChange,
                        true
                    )
                }
            );
        }

        columns.push(
            {
                id: "part2_serviceType",
                Header: "Service Type",
                sortable: false,
                filterable: false,
                className: 'text-left',
                accessor: function (d) {
                    if (d.Part2) {
                        return serviceCodeTypeToIcon(d.Part2.ServiceUserPartType, true);
                    }
                },
            }
        );
  
        columns.push(
            {
                id: "part1_identifier",
                Header: "SIP User",
                accessor: function (d) {
                    var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId && s.ServiceVariantCode === "TRUNK"});
                    if (service) {
                        return d.Part1.Identifier;
                    }
                    else if (d.Part1.IsSynced && d.Part1.Name) {
                        return d.Part1.Name;
                    } else {
                        return d.Part1.Identifier;
                    }
                },
            }
        );

        var pbxServices = _.filter(self.props.services, { ServiceCode: 'pbx' });

        if (pbxServices.length > 1) {
            columns.push(
                {
                    id: "part1_service",
                    Header: getPbxLabel(self.props.account),
                    sortable: false,
                    accessor: function (d) {
                        // Get service, then service domain for this column
                        var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId });
                        if (service && service.Name) {
                            return service.Name;
                        }
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "true") {
                            return true;
                        }
                        return false;
                    },
                    Filter: ({ filter, onChange }) => self.serviceFilter(
                        _.filter(self.props.services, function (s) { return s.ServiceCode === 'pbx' }),
                        filter,
                        onChange,
                        false
                    )
                }
            );
        }

        columns.push(
            {
                id: "registration",
                Header: "Registration",
                accessor: function (d) {
                    var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId });
                    if (!service || (service && service.ServiceVariantCode === 'TRUNK')) {
                        return null;
                    }
                    return (
                        <UserPBXRegistrationLight
                            updateTime={self.state.updateTime}
                            service={service}
                            User={d.Part1}
                        />
                    );
                },
                className: 'col-has-tooltip col-status-light',
                width: 100,
                sortable: false,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: function ({ filter, onChange }) {
                    return (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option key='all' value='all'>All</option>
                            <option key='badregistration' value='badRegistration'>Failed Registrations</option>
                        </select>
                    );
                }
            }
        );

        columns.push(
            {
                id: "calls",
                Header: "Calls",
                accessor: function (d) {
                    if (d.Part2 && d.Part2.AccountServiceId) {
                        var service = _.find(self.props.services, function (s) { return s.Id === d.Part2.AccountServiceId });
                        return (
                            d.Id ? (
                                <CallLight {...self.props} service={service} User={d}
                                    triggerCallLog={() => self.props.triggerCallLog(service, d)}
                                />
                            ) : null
                        );
                    } else {
                        return null;
                    }
                },
                sortable: false,
                className: 'col-status-light col-has-tooltip',
                width: 50,
                filterable: false
            }
        );

        columns.push(
            {
                id: "licencestatus",
                Header: "",
                accessor: function (d) {
                    var iconClass = null;
                    var message = null;
                    if (d.LicenceState === undefined || !d.LicenceState) {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = "State Unknown";
                    } else if (d.LicenceState.toLowerCase() === 'licenced') {
                        // <i className="fa-solid fa-check" title={d.LicenceState}></i>
                        return (null);
                    } else if (d.LicenceState.toLowerCase() === 'unlicenced') {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = d.LicenceState;
                    } else if (d.LicenceState.toLowerCase() === 'pendinglicence') {
                        iconClass = "fa-solid fa-hourglass-half pending";
                        message = "Pending";
                    } else if (d.LicenceState.toLowerCase() === 'disabled') {
                        iconClass = "fa-solid fa-xmark fa-times--grey";
                        message = d.LicenceState;
                    } else {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = "Unknown: " + d.LicenceState;
                    }

                    return (
                        <div className="q-popup-status-wrapper">
                            <i className={iconClass}></i>
                            <div className="q-tooltip row-hover-only">
                                <h5>Licence Status</h5>
                                <p>{message}</p>
                            </div>
                        </div>
                    );
                },
                sortable: false,
                width: 50,
                className: 'col-status-light col-has-tooltip',
                filterable: false
            }
        );

        return columns;
    }

    render() {
        var self = this;
        const data = this.state.data;

        var cols = self.getColumns();

        return (
            <div>

                <ReactTable
                    data={data}
                    pages={this.state.pageCount}
                    onPageChange={()=>{
                        self.closeAllRows();
                    }}
                    expanded={this.state.expanded}
                    resizable={false}
                    filterable
                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
                    columns={cols}
                    minRows={0}
                    showPageJump={false}
                    multiSort={false}
                    loading={this.state.loading}
                    loadingText={"Loading..."}
                    noDataText={"You don't have any users yet. Click the add button above to get started."}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    className="-striped -highlight"
                    // Tell react-table that we'll be sorting pagination and sorting via server side
                    manual
                    onFetchData={function (state, instance) {
                        self.updateData(state, instance);
                    }}
                    SubComponent={row => {
                        var { loadCompleteCallback, loadStartingCallback, setRefreshPause, closeForm, services, ...otherProps } = { ...self.props };
                        return (
                            <EditUserForm
                                countries={otherProps.countries}
                                closeForm={self.closeAllRows}
                                user={row.original}
                                closeButtonText={"Close"}
                                submitButtonText={"Save"}
                            />
                        );
                    }}
                    // Important to allow manual updating
                    ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                    style={{zIndex: '0'}}
                />
            </div>
        );
    }

    closeAllRows(e) {
        if (e !== undefined) {
            e.preventDefault();
        }

        // Ditch all expanded rows...
        this.setState({
            expanded: {},
            hasRowExpanded: false
        });

        // Remove 'pause' character
        this.props.setRefreshPause && this.props.setRefreshPause(false);

        // Always fire off an update, as we would skip updates whilst the row was expanded
        this.props.userUpdateTrigger && this.props.userUpdateTrigger(true);
    }

    handleRowExpanded(newExpanded, index, event) {
        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
                hasRowExpanded: true
            });

            // Mark a refresh in progress to stop updates
            this.props.setRefreshPause && this.props.setRefreshPause(true);
        }
    }

    serviceFilter(allServices, filter, onChange, useServiceId) {
        var opts;
        if (useServiceId) {
            opts = _.map(allServices, function (s) { return (<option key={allServices.indexOf(s)} value={s.ServiceId}>{s.Name}</option>) });
        } else {
            opts = _.map(allServices, function (s) { return (<option key={s.Id} value={s.Id}>{s.Name}</option>) });
        }

        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services: services.rawList
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(UserReactTable);
