import React, { Component } from 'react';
import { cmsGetServiceSyncJobs } from '../../CallMSAPI.js';

import SyncJobOutput from './SyncJobOutput';
import ExpandingReactTable from '../../ExpandingReactTable';
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import { serviceCodeToIcon } from '../../CallMSUIHelpers.js'
import { serviceNameExpander } from '../ServiceFormHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

var _ = require('lodash');
var moment = require('moment-timezone');


class SyncJobTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1,

            // Not specific to react-table
            hasRowExpanded: false
        }

        this.updateData = this.updateData.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.userUpdateTrigger = this.userUpdateTrigger.bind(this);

        this.tableRef = React.createRef();
    }

    //Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            // If the table has an open row, it's fine as row stays open...
            this.tableRef.current.forceTableUpdate();
        }
    }

    //
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.data != this.state.data
            || nextProps.services != this.props.services
            || nextProps.lastUpdate != this.props.lastUpdate) {
            return true;
        } else {
            return false;
        }
    }

    updateData(state) {
        var self = this;

        var apiParams = {
            'SortBy': 'CreatedOn',
            'SortDirection': 'Descending',
            'IncludeInactive': true
        };

        // Handle sorting/order
        if (state.sorted.length > 0) {
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Next, API level filters
                if (f.id === 'service') {
                    apiParams['accountServiceId'] = f.value;
                    return;
                } else if (f.id === 'state') {
                    apiParams['State'] = f.value;
                    return;
                }
            })
        }

        cmsGetServiceSyncJobs(
            self.props.account.Id,
            apiParams,
            function (callmsData) {
                self.setState({
                    data: callmsData.Results,
                    pageCount: callmsData.PageCount,
                    loading: false
                });
                self.props.loadCompleteCallback && self.props.loadCompleteCallback();
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "state_change",
                Header: "Date",
                accessor: function (d) {
                    let t = moment.tz(d.StateChangedOn, "UTC");
                    if(t.isValid()) {
                        if (t.year() === moment().year()) {
                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                        } else {
                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                        }
                    } else {
                        return d.StateChangedOn;
                    }
                },
                filterable: false
            }
        );

        columns.push(
            {
                id: "service",
                Header: "Service",
                accessor: function (d) {
                    var service = _.find(self.props.services, { Id: d.AccountServiceId });

                    var debugIcon = null;
                    if (d.CaptureDebug) {
                        debugIcon = <i className="fa-solid fa-bug"></i>
                    }

                    var exceptionIcon = null;
                    if (d.HasException && self.props.hasRole('ViewExceptions')) {
                        exceptionIcon = <i className="fa-solid fa-triangle-exclamation"></i>
                    }

                    if (service) {
                        var icon = serviceCodeToIcon(service.ServiceCode);
                        var displayName = serviceNameExpander(service);
                        return <span title={d.Id}>{icon} {displayName} {debugIcon} {exceptionIcon}</span>;
                    } else {
                        return <em title={d.Id}>Unknown service {d.AccountServiceId} {debugIcon} {exceptionIcon}</em>;
                    }
                },
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.serviceFilter(filter, onChange, 'service')
            }
        );

        columns.push(
            {
                id: "state",
                Header: "State",
                accessor: function (d) {
                    if (d.State === 'Completed') {
                        return <i className="fa-solid fa-check"></i>;
                    } else {
                        return d.State;
                    }
                },
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.serviceFilter(filter, onChange, 'state')
            }
        );

        return columns;
    }

    serviceFilter(filter, onChange, type) {
        var self = this;
        var opts = null;
        switch (type) {
            case 'state':
                opts = [
                    <option key={'Failed'} value={'Failed'}>Failed</option>,
                    <option key={'Completed'} value={'Completed'}>Completed</option>,
                    <option key={'Running'} value={'Running'}>Running</option>,
                    <option key={'Queued'} value={'Queued'}>Queued</option>,
                    <option key={'Cancelled'} value={'Cancelled'}>Cancelled</option>,
                    <option key={'ManualIntervention'} value={'ManualIntervention'}>ManualIntervention</option>
                ];
                break;
            case 'service':
                opts = _.map(
                    _.sortBy(
                        _.filter(
                            self.props.services, function (s) { s.DisplayName = serviceNameExpander(s); return s.SyncEnabled }
                        ),
                        'DisplayName'
                    ),
                    function (s) {
                        return (<option key={s.Id} value={s.Id}> {s.DisplayName}</ option>);
                    }
                );
                break;
        }

        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

    userUpdateTrigger = () => {
        this.tableRef.current && this.tableRef.current.forceTableUpdate();
    }

    render() {
        var self = this;
        const data = this.state.data;

        var cols = self.getColumns();

        return (
            <ExpandingReactTable
                data={data}
                pages={this.state.pageCount}
                updateData={self.updateData}
                userUpdateTrigger={this.userUpdateTrigger}
                resizable={false}
                filterable
                columns={cols}
                minRows={0}
                showPageJump={false}
                multiSort={false}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"There are no sync jobs to show."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                // Important to allow manual updating
                ref={this.tableRef}
                SubComponent={row => {
                    return <SyncJobOutput jobId={row.original.Id} />;
                }}
            />
        );
    }
}
export default connect(state => ({
    account: state.account.account
}), dispatch => ({
    hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
}))(SyncJobTable);
