import React, { useState, useEffect } from "react";
import { TeachingBubble, Toggle } from "@fluentui/react";
import AutoSyncRow from "./AutoSyncRow";

const NextGenSyncRow = (props) => {
  const [isNextGen, setIsNextGen] = useState(
    props.account.IsBetaFeaturesEnabled
      ? props.account.IsBetaFeaturesEnabled.includes("ENABLENEXTGENSYNC")
      : false
  );
  const [showNextGenSyncHelp, setShowNextGenSyncHelp] = useState(false);

  const toggleNextGenSyncHelp = () => {
    setShowNextGenSyncHelp(!showNextGenSyncHelp);
  };

  const dismissNextGenSyncHelp = () => {
    setShowNextGenSyncHelp(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Toggle
            label={
              <div>
                <label htmlFor="nextGenSyncBtn">
                  Next Gen Teams Sync
                </label>
                <button
                  id="nextGenSyncBtn"
                  className="btn btn-link btn--faux-link"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleNextGenSyncHelp();
                  }}
                >
                  <i
                    className="fa-solid fa-question-circle"
                    id={"nextGenSyncHelp"}
                  />
                </button>
                {showNextGenSyncHelp && (
                  <TeachingBubble
                    target={"#nextGenSyncHelp"}
                    hasCondensedHeadline={true}
                    onDismiss={dismissNextGenSyncHelp}
                    hasCloseIcon={true}
                    closeButtonAriaLabel="Close"
                  >
                    <>
                      <p>
                        Toggle this on to activate our next generation of the Teams sync 
                        process which includes a more efficient sync and proactive error reporting.
                      </p>
                    </>
                  </TeachingBubble>
                )}
              </div>
            }
            defaultChecked={props.values.NextGenSyncToggle}
            onText="On"
            offText="Off"
            onChange={(_e, checked) => {
              if (!checked) {
                props.setFieldValue("NextGenSyncToggle", checked);
              }
              props.setFieldValue("NextGenSyncToggle", checked);
            }}
            disabled={props.disabled}
          />
        </div>
        {props.values.ShowAutoSyncToggle &&
            <AutoSyncRow
                account={props.account}
                values={props.values}
                errors={props.errors}
                touched={props.touched}
                setFieldValue={props.setFieldValue}
                disabled={props.disabled}
            />
        }
      </div>
    </>
  );
};
export default NextGenSyncRow;
